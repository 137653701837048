import { store } from '../redux/store.js';
import { errorHandler } from '../utils/errorHandler.js';

const API_URL = process.env.REACT_APP_BACKEND_API_URL;
const token = sessionStorage.getItem('accessToken');

const ContentService = {
  searchContent: async (term, limit = undefined, offset = undefined) => {
    const tenantName = store.getState().tenant.tenant;
    try {
      const params = new URLSearchParams({ term });

      if (limit !== undefined && offset !== undefined) {
        params.append('limit', limit);
        params.append('offset', offset);
      }

      const url = `${API_URL}/api/v1/content/search?${params.toString()}`;

      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-Tenant-ID': `${tenantName}`,
          'Authorization': `Bearer ${token}`
        }
      });

      errorHandler(response.status);

      if (response.ok) {
        return response.json();
      }
      return [];
    } catch (error) {
      console.error('Error fetching Content:', error);
      throw error;
    }
  },

  createContent: async (data) => {
    const tenantName = store.getState().tenant.tenant;
    const formData = new FormData();

    if (data.file) {
      formData.append('file', data.file);
    }

    const contentPayload = {
      resource: data.resource,
      name: data.name,
      description: data.description,
      active: data.active,
      categoryId: data.categoryId,
      subcategoryId: data.subcategoryId,
      creatorId: data.creatorId,
      userIds: data.userIds,
      guestEmails: data.guestEmails,
      live: data.live,
      tags: data.tags
    };

    // Include recurrencePattern or occurrences based on the data
    if (data.recurrencePattern) {
      contentPayload.recurrencePattern = data.recurrencePattern;
    } else if (data.occurrences) {
      contentPayload.occurrences = data.occurrences;
    }

    formData.append('content', new Blob([JSON.stringify(contentPayload)], { type: "application/json" }));

    try {
      const response = await fetch(`${API_URL}/api/v1/content`, {
        method: 'POST',
        headers: {
          'X-Tenant-ID': `${tenantName}`,
          'Authorization': `Bearer ${token}`
        },
        body: formData
      });

      errorHandler(response.status);

      if (response.ok) {
        return await response.json();
      } else {
        const errorResponse = await response.json();
        console.error('Error response:', errorResponse);
        throw new Error(`Error creating content: ${errorResponse.message}`);
      }
    } catch (error) {
      console.error('Error:', error.message);
      throw new Error('Error creating content');
    }
  },

  updateContent: async (data) => {
    const tenantName = store.getState().tenant.tenant;
    const formData = new FormData();

    if (data.file instanceof File) {
      formData.append('file', data.file);
    }

    const contentPayload = {
      id: data.id,
      resource: data.resource,
      name: data.name,
      description: data.description,
      active: data.active,
      categoryId: data.categoryId,
      subcategoryId: data.subcategoryId,
      creatorId: data.creatorId,
      userIds: data.userIds,
      guestEmails: data.guestEmails,
      live: data.live,
      image: data.file instanceof File ? null : data.file,
      tags: data.tags
    };

    if (data.recurrencePattern) {
      contentPayload.recurrencePattern = data.recurrencePattern;
    } else if (data.occurrences) {
      contentPayload.occurrences = data.occurrences;
    }

    formData.append('content', new Blob([JSON.stringify(contentPayload)], { type: "application/json" }));

    try {
      const response = await fetch(`${API_URL}/api/v1/content/${data.id}`, {
        method: 'PUT',
        headers: {
          'X-Tenant-ID': `${tenantName}`,
          'Authorization': `Bearer ${token}`
        },
        body: formData
      });

      errorHandler(response.status);

      if (response.ok) {
        return await response.json();
      } else {
        const errorResponse = await response.json();
        console.error('Error response:', errorResponse);
        throw new Error(`Error updating content: ${errorResponse.message}`);
      }
    } catch (error) {
      console.error('Error updating Content:', error);
      throw error;
    }
  },
  deleteContent: async (contentId) => {
    const tenantName = store.getState().tenant.tenant;
    try {
      const response = await fetch(API_URL + '/api/v1/content/' + contentId.toString(), {
        method: 'DELETE',
        headers: {
          'X-Tenant-ID': `${tenantName}`,
          'Authorization': `Bearer ${token}`
        },
      });

      errorHandler(response.status);

      if (response.ok) {
        return;
      }
      return [];
    } catch (error) {
      console.error('Error fetching Content:', error);
      throw error;
    }
  },
  getCategories: async () => {
    const tenantName = store.getState().tenant.tenant;
    try {
      const response = await fetch(API_URL + '/api/v1/content/categories', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-Tenant-ID': `${tenantName}`,
          'Authorization': `Bearer ${token}`
        }
      });

      errorHandler(response.status);

      if (response.ok) {
        return response.json();
      }
      return [];
    } catch (error) {
      console.error('Error fetching Content:', error);
      throw error;
    }
  },
  getSubcategories: async () => {
    const tenantName = store.getState().tenant.tenant;
    try {
      const response = await fetch(API_URL + '/api/v1/content/subcategories', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-Tenant-ID': `${tenantName}`,
          'Authorization': `Bearer ${token}`
        }
      });

      errorHandler(response.status);

      if (response.ok) {
        return response.json();
      }
      return [];
    } catch (error) {
      console.error('Error fetching Content:', error);
      throw error;
    }
  },
  getContentById: async (contentId) => {
    const tenantName = store.getState().tenant.tenant;
    try {
      const response = await fetch(API_URL + '/api/v1/content/' + contentId.toString(), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-Tenant-ID': `${tenantName}`,
          'Authorization': `Bearer ${token}`
        }
      });

      errorHandler(response.status);

      if (response.ok) {
        return response.json();
      }
      return [];
    } catch (error) {
      console.error('Error fetching Content:', error);
      throw error;
    }
  },
  getContentByUserAndDate: async (start, end, limit = undefined, offset = undefined, categoryIds = undefined) => {
    const tenantName = store.getState().tenant.tenant;
    try {
      const params = new URLSearchParams();

      if (start) {
        params.append('start', start.toISOString());
      }

      if (end) {
        params.append('end', end.toISOString());
      }

      if (limit !== undefined && offset !== undefined) {
        params.append('limit', limit);
        params.append('offset', offset);
      }

      if (categoryIds) {
        const categoryIdsParam = categoryIds.join(',');
        params.append('categoryIds', categoryIdsParam);
      }

      const url = `${API_URL}/api/v1/content/events?${params.toString()}`;

      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-Tenant-ID': `${tenantName}`,
          'Authorization': `Bearer ${token}`
        }
      });

      errorHandler(response.status);

      if (response.ok) {
        return response.json();
      }
      return [];
    } catch (error) {
      console.error('Error fetching Content by user and date:', error);
      throw error;
    }
  },

  patchContentOccurrence: async (id, data) => {
    const tenantName = store.getState().tenant.tenant;
    const token = sessionStorage.getItem('accessToken');

    const response = await fetch(`${API_URL}/api/v1/content/occurrences/${id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-Tenant-ID': tenantName,
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(data)
    });

    if (!response.ok) {
      const errorMessage = await response.text();
      throw new Error(`Error: ${response.status} - ${errorMessage}`);
    }

    return response.json();
  },
  getNextOccurrence: async (contentId) => {
    const tenantName = store.getState().tenant.tenant;
    try {
      const response = await fetch(`${API_URL}/api/v1/content/nextOccurrence/${contentId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-Tenant-ID': `${tenantName}`,
          //'Authorization': `Bearer ${token}`
        }
      });

      errorHandler(response.status);

      if (response.ok) {
        return response.json();
      }
      return null;
    } catch (error) {
      console.error('Error fetching next occurrence:', error);
      throw error;
    }
  },
}

export const { searchContent, createContent, updateContent, deleteContent, getSubcategories, getCategories, getContentById, getContentByUserAndDate, patchContentOccurrence, getNextOccurrence } = ContentService;
