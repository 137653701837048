import React from 'react';
import styles from './styles/tag.module.scss';

const Tag = ({ tag, onRemove, showRemoveButton = true }) => {
    return (
        <div className={styles.tag}>
            {tag}
            {showRemoveButton && (
                <button
                    className={styles.removeTagButton}
                    onClick={() => onRemove(tag)}
                >
                    ✕
                </button>
            )}
        </div>
    );
};

export default Tag;
