import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { store } from "../../redux/store";
import { setSelectedComponent, setSelectedLayout } from '../../redux/slices/layoutSlice';
import ContentView from '../content-view/contentView.component';
import { hasAdminRole, hasManagerRole, hasProfesionalRole } from '../../utils/credentials.util';

const ContentWrapper = () => {
    useEffect(() => {
        store.dispatch(setSelectedComponent('content'));
        store.dispatch(setSelectedLayout('vertical'));
    }, []);

    const hasAccessToProtectedRoutes = hasAdminRole() || hasProfesionalRole() || hasManagerRole();

    if (!hasAccessToProtectedRoutes) {
        return <Navigate to="/contacts" replace />;
    }

    return <ContentView />;
};

export default ContentWrapper;
