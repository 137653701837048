import React, { useState, useEffect } from 'react';
import Modal from '../../modal/modal.component';
import DateTimeInput from '../../date-time-input/dateTimeInput.component';
import styles from './styles/editContentModal.module.scss';
import { patchContentOccurrence } from "../../../services/ContentService";
import { withTranslation } from 'react-i18next';

const EditContentModal = ({ onClose, event, t }) => {
    const [editedStartDate, setEditedStartDate] = useState(new Date(event.raw.startDate));
    const [editedEndDate, setEditedEndDate] = useState(new Date(event.raw.endDate));

    useEffect(() => {
        if (event) {
            setEditedStartDate(new Date(event.raw.startDate));
            setEditedEndDate(new Date(event.raw.endDate));
        }
    }, [event]);

    const handleSubmit = async () => {

        const patchData = {
            startDate: editedStartDate,
            endDate: editedEndDate,
            live: event.raw.live
        };

        try {
            const updatedContent = await patchContentOccurrence(event.id, patchData);
            console.log('Contenido actualizado exitosamente:', updatedContent);
            onClose(updatedContent);
        } catch (error) {
            console.error('Error al actualizar las fechas:', error);
        }
    };
    const buttons = [
        { label: t("CONTENT.CREATE.SaveButton"), className: styles.saveButton, onClick: handleSubmit },
        { label: t("CONTENT.CREATE.CancelButton"), className: styles.cancelButton, onClick: () => onClose(false) }
      ];
    return (
        
        <Modal
            title={t("CONTENT.EDIT.EditRepetitionTitle")}
            titleClassName={styles.modalTitle}
            onClose={() => onClose(null)}
            buttons={buttons}
        >
            <div className={styles.editModalContent}>
                <div className={styles.inputGroup}>
                    <label htmlFor="startDate">{t("CONTENT.CREATE.StartDate")}</label>
                    <DateTimeInput
                        id="startDate"
                        value={editedStartDate}
                        label={t("CONTENT.CREATE.StartDate")}
                        onChange={setEditedStartDate}
                    />
                </div>
                <div className={styles.inputGroup}>
                    <label htmlFor="endDate">{t("CONTENT.CREATE.EndDate")}</label>
                    <DateTimeInput
                        id="endDate"
                        value={editedEndDate}
                        label={t("CONTENT.CREATE.EndDate")}
                        onChange={setEditedEndDate}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default withTranslation()(EditContentModal);
