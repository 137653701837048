import React, { useEffect } from 'react';
import { store } from "../../redux/store";
import { setSelectedComponent, setSelectedLayout } from '../../redux/slices/layoutSlice';
import CalendarView from '../calendar-view/calendarView.component';

const CalendarWrapper = () => {
    const initLayout = () => {
        store.dispatch(setSelectedComponent('calendar'));
        store.dispatch(setSelectedLayout('vertical'));
    };
    useEffect(() => {
        initLayout();
    }, []);
    return <CalendarView />;
};

export default CalendarWrapper;
