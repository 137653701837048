import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  rows: [],
};

const contentSlice = createSlice({
  name: 'content',
  initialState,
  reducers: {
    setRows: (state, action) => {
      state.rows = action.payload;
    },
    toggleActiveStatus: (state, action) => {
      const { rowId, active } = action.payload;
      const row = state.rows.find(row => row.id === rowId);
      if (row) {
        row.active = active;
      }
    },
    clearRows: (state) => {
      state.rows = [];
    },
  },
});

export const { setRows, toggleActiveStatus, clearRows } = contentSlice.actions;
export default contentSlice.reducer;
