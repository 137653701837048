import React, { Component } from "react";
import { Icon } from '@iconify/react';
import { withTranslation } from "react-i18next";
import UserProfile from "./user-profile/userProfile.component";
import ConfigProfile from "./config-profile/configProfile.component";
import NotificationProfile from "./notification-profile/notificationProfile.component";
import HelpSection from "./help-section/helpSection.component";
import { hasFamiliarRole } from "../../utils/credentials.util";

import familiarStyles from './styles/profileViewFamiliar.module.scss';
import defaultStyles from './styles/profileView.module.scss';

const menu = [
    {
        key: 'profile',
        icon: 'mage:user',
        label: 'MENU.LABEL.Profile',
        action: 'profile',
    },
    {
        key: 'config',
        icon: 'vaadin:cog-o',
        label: 'MENU.LABEL.Config',
        action: 'config',
    },
    {
        key: 'notifications',
        icon: 'fluent:alert-24-regular',
        label: 'MENU.LABEL.Notifications',
        action: 'notifications',
    },
    {
        key: 'help',
        icon: 'formkit:help',
        label: 'MENU.LABEL.Help',
        action: 'help',
    },
];

class IconWithLabel extends Component {
    render() {
        const styles = hasFamiliarRole() ? familiarStyles : defaultStyles;
        const { icon, label, onClick, menuItemKey, selectedComponent } = this.props;
        const iconClass = selectedComponent === menuItemKey ? `${styles.iconWithLabel} ${styles.selected}` : styles.iconWithLabel;

        return (
            <div className={iconClass} onClick={onClick}>
                <Icon icon={icon} className={styles.menuIcon} />
                <div className={styles.label}>{label}</div>
            </div>
        );
    }
}

class ProfileView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedComponent: props.selectedComponent || menu[0].key,
            mainContent: null,
        };
    }

    componentDidMount() {
        this.handleIconClick(this.props.selectedComponent || menu[0].key, this.props.selectedComponent || menu[0].action);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.selectedComponent !== this.props.selectedComponent) {
            this.handleIconClick(this.props.selectedComponent || menu[0].key, this.props.selectedComponent || menu[0].action);
        }
    }


    handleIconClick = (key, action) => {
        this.setState({ selectedComponent: key });
        this.handleActionClick(action);
    }

    handleActionClick = (action) => {
        switch (action) {
            case 'profile':
                this.setState({ mainContent: <UserProfile /> });
                break;
            case 'config':
                this.setState({ mainContent: <ConfigProfile /> });
                break;
            case 'notifications':
                this.setState({ mainContent: <NotificationProfile /> });
                break;
            case 'help':
                this.setState({ mainContent: <HelpSection /> });
                break;
            default:
                this.setState({ mainContent: null });
                break;
        }
    }

    render() {
        const { t } = this.props;
        const { selectedComponent, mainContent } = this.state;
        const styles = hasFamiliarRole() ? familiarStyles : defaultStyles;

        return (
            <div className={styles.profileView}>
                <nav className={`${styles.navigation}`}>
                    {menu.map((option) => (
                        <div className={styles.menuRow} key={option.key}>
                            <IconWithLabel
                                icon={option.icon}
                                label={t(option.label)}
                                onClick={() => this.handleIconClick(option.key, option.action)}
                                menuItemKey={option.key}
                                selectedComponent={selectedComponent}
                            />
                        </div>
                    ))}
                </nav>
                <div className={styles.componentDisplay}>
                    {mainContent}
                </div>
            </div>
        );
    }
}

export default withTranslation()(ProfileView);
