import React, { Component } from "react";
import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import styles from "./styles/selectButton.module.scss";
import { withTranslation } from "react-i18next";

class SelectButton extends Component {
    render() {
        const { icon, label, selectedCount, onClick, t, validate, error } = this.props;

        let buttonClass = styles.selectButton;
        if (selectedCount > 0) {
            buttonClass += ` ${styles.active}`;
        }
        if (validate && selectedCount === 0) {
            buttonClass += ` ${styles.error}`;
        }

        return (
            <button className={buttonClass} onClick={onClick}>
                <Icon icon={icon} className={styles.icon} />
                <span className={styles.text}>
                    {selectedCount > 0
                        ? `+${selectedCount} ${label}...`
                        : `${t("NOTIFICATIONS.LABEL.Select")} ${label}`}
                </span>
                <Icon icon="mdi:plus" className={styles.plusIcon} />

                {validate && selectedCount === 0 && (
                    <div className={styles.tooltip}>
                        <Icon icon="mingcute:alert-fill" width="24px" height="24px" className={styles.errorIcon} />
                        <span className={styles.tooltiptext}>
                            {error || t("NOTIFICATIONS.ERROR.DefaultError")}
                        </span>
                    </div>
                )}
            </button>
        );
    }
}

SelectButton.propTypes = {
    icon: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    selectedCount: PropTypes.number,
    onClick: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    validate: PropTypes.bool,
    error: PropTypes.string,
};

SelectButton.defaultProps = {
    selectedCount: 0,
    validate: false,
    error: "",
};

export default withTranslation()(SelectButton);
