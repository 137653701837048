import { store } from '../redux/store.js';
import { errorHandler } from '../utils/errorHandler.js';

const API_URL = process.env.REACT_APP_BACKEND_API_URL;
const token = sessionStorage.getItem('accessToken');

const BlobService = {
    getImage: async (blobUrl) => {
        const tenantName = store.getState().tenant.tenant;
        try {
            const response = await fetch(`${API_URL}/api/v1/blob/download?blobUrl=${encodeURIComponent(blobUrl)}`, {
                method: 'GET',
                headers: {
                    'X-Tenant-ID': `${tenantName}`,
                    'Authorization': `Bearer ${token}`
                },
            });

            errorHandler(response.status);

            if (response.ok) {
                const blob = await response.blob();
                return URL.createObjectURL(blob);
            }
            throw new Error('Failed to fetch image');
        } catch (error) {
            console.error('Error fetching Content Image:', error);
            throw error;
        }
    }
}

export const { getImage } = BlobService;
