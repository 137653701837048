import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
import Modal from '../../modal/modal.component';
import CustomModal from '../../custom-modal/customModal.component';
import UploadFileModal from '../../upload-file-modal/uploadFileModal.component';
import { updateUser } from '../../../services/UserService';
import { getUserTypes } from '../../../services/UserTypeService';
import { searchHomes } from '../../../services/HomeService';
import DropdownSelect from '../../dropdown-select/dropdownSelect.component';
import CustomInput from '../../custom-input/customInput.component';
import SendNewPasswordRequest from '../send-new-password-request/sendNewPasswordRequest.component';
import SendNewPasswordSelection from '../send-new-password-selection/sendNewPasswordSelection.component';
import SendNewPasswordConfirmation from '../send-new-password-confirmation/sendNewPasswordConfirmation.component';
import ModalFeedback from '../../modal-feedback/modalFeedback.component';
import LoadingScreen from '../../loading-screen/loadingScreen.component';
import styles from './styles/updateUsers.module.scss';
import greyImage from '../../../assets/img/imagegrey.png';
import blueImage from '../../../assets/img/imageblue.png';
import { getImage } from '../../../services/BlobService';
import { Icon } from '@iconify/react';
import TagInput from "../../tags/tag-input/tagInput.component";

class UpdateUsers extends Component {
  state = {
    usersData: {
      user: '',
      name: '',
      surname: '',
      password: '',
      telephone: '',
      birthDate: '',
      direction: '',
      locality: '',
      province: '',
      postalCode: ''
    },
    userTypes: [],
    homes: [],
    selectedUserType: null,
    selectedHomes: [],
    tags: [],
    imageSrc: greyImage,
    isUploadModalOpen: false,
    selectedFile: null,
    showNewPasswordModal: false,
    showPasswordSelectionModal: false,
    showPasswordConfirmationModal: false,
    isFeedbackModalOpen: false,
    feedbackType: null,
    feedbackMessage: '',
    errors: {},
    validate: false,
    isLoading: true,
    isImageLoading: true,
    showUpdateConfirmationModal: false,
  };

  async componentDidMount() {
    try {
      const userTypes = await getUserTypes();
      this.setState({ userTypes: userTypes.map(type => ({ key: type.id, value: this.props.t("USER_TYPE." + type.name) })) });

      const homes = await this.searchHomes("");
      this.setState({ homes });

      const { user } = this.props;
      if (user) {
        this.setState({
          usersData: {
            user: user.email,
            name: user.name,
            surname: user.surname,
            password: '',
            telephone: user.telephone,
            birthDate: user.birthDate,
            direction: user.direction,
            locality: user.locality,
            province: user.province,
            postalCode: user.postalCode,
            active: user.active,
            moderator: user.moderator
          },
          selectedUserType: user.userType ? { key: user.userTypeId, value: this.props.t("USER_TYPE." + (user.userType.name ? user.userType.name : "null")) } : null,
          selectedHomes: user.homeIds.map(homeId => ({ key: homeId, value: null })),
          tags: user.tags,
          imageSrc: greyImage,
          selectedFile: user.avatar || null,
        }, () => {
          if (this.state.selectedFile !== null) {
            this.fetchImage(this.state.selectedFile);
          } else {
            this.setState({ isImageLoading: false, isLoading: false });
          }
        });
      }
    } catch (error) {
      console.error('Error obteniendo users:', error);
      this.setState({ isLoading: false });
    }
  }

  fetchImage = async (imageId) => {
    if (imageId) {
      this.setState({ isImageLoading: true });
      try {
        const url = await getImage(imageId);
        this.setState({ imageSrc: url, isImageLoading: false, isLoading: false });
      } catch (error) {
        console.error('Error fetching image:', error);
        this.setState({ isImageLoading: false, isLoading: false });
      }
    } else {
      this.setState({ isImageLoading: false, isLoading: false });
    }
  }; 

  validateFields = () => {
    const { usersData, selectedUserType } = this.state;
    const errors = {};

    if (!usersData.name) errors.name = "Este campo es obligatorio";
    if (!usersData.surname) errors.surname = "Este campo es obligatorio";
    if (!usersData.user) errors.user = "Este campo es obligatorio";
    if (usersData.user && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(usersData.user)) errors.user = "El email no es válido";
    if (!selectedUserType) errors.selectedUserType = "Este campo es obligatorio";

    this.setState({ errors, validate: true });

    return errors;
  };

  handleSave = () => {
    const errors = this.validateFields();
    if (Object.keys(errors).length > 0) {
      return;
    }

    this.setState({ showUpdateConfirmationModal: true });
  };

  handleConfirmUpdate = async () => {
    this.setState({ showUpdateConfirmationModal: false, isLoading: true });

    const { usersData, selectedUserType, selectedHomes, selectedFile, tags } = this.state;

    const data = {
      id: this.props.user.id,
      file: selectedFile || null,
      email: usersData.user || null,
      name: usersData.name || null,
      surname: usersData.surname || null,
      password: usersData.password || null,
      phone: usersData.telephone || null,
      birthDate: usersData.birthDate || null,
      direction: usersData.direction || null,
      locality: usersData.locality || null,
      province: usersData.province || null,
      postalCode: usersData.postalCode || null,
      userTypeId: selectedUserType.key || null,
      homeIds: selectedHomes.map(home => home.key),
      active: usersData.active,
      moderator: usersData.moderator,
      tags: tags
    };

    try {
      await updateUser(data);
      if (usersData.password !== '') {
        this.setState({ showPasswordSelectionModal: true });
      } else {
        this.setState({
          showPasswordSelectionModal: false,
          isFeedbackModalOpen: true,
          feedbackType: 'ok',
          feedbackMessage: this.props.t("USERS.EDIT.SuccessMessage"),
        });
      }
    } catch (error) {
      console.error('Error actualizando usuario:', error);
      this.setState({
        isFeedbackModalOpen: true,
        feedbackType: 'error',
        feedbackMessage: this.props.t("USERS.EDIT.ErrorMessage"),
      });
    } finally {
      this.setState({ isLoading: false });
    }
  };

  handleCancelUpdate = () => {
    this.setState({ showUpdateConfirmationModal: false });
  };

  closeFeedbackModal = () => {
    this.setState({ isFeedbackModalOpen: false });
    this.props.onClose(this.state.feedbackType === 'ok');
  };

  setSelectedHomes = async (selectedOptions) => {
    this.setState({ selectedHomes: Array.isArray(selectedOptions) ? selectedOptions : [] });
  };

  setSelectedUserType = (selectedOptions) => {
    this.setState({ selectedUserType: Array.isArray(selectedOptions) ? selectedOptions[0] : null });
  };
  
  handleTagsChange = (tags) => {
    this.setState({ tags });
  }

  searchHomes = async (term) => {
    try {
      const homes = await searchHomes(term);
      const selectedHomeIds = this.state.selectedHomes.map(home => home.key);
      const filteredHomes = homes.filter(home => home.active || selectedHomeIds.includes(home.id));
      return filteredHomes.map(home => ({ key: home.id, value: home.niu }));
    } catch (error) {
      console.error('Error buscando homes:', error);
      return [];
    }
  };

  handleInputChange = (name, value) => {
    this.setState((prevState) => ({
      usersData: {
        ...prevState.usersData,
        [name]: value
      },
      errors: {
        ...prevState.errors,
        [name]: null
      }
    }));
  };

  handleImageMouseEnter = () => {
    if (this.state.selectedFile) {
      return;
    }
    this.setState({ imageSrc: blueImage });
  };

  handleImageMouseLeave = () => {
    if (this.state.selectedFile) {
      return;
    }
    this.setState({ imageSrc: greyImage });
  };

  handleImageClick = () => {
    this.setState({ isUploadModalOpen: true });
  };

  closeUploadModal = () => {
    this.setState({ isUploadModalOpen: false });
  };

  handleFileSelect = (file) => {
    this.setState({ selectedFile: file, imageSrc: URL.createObjectURL(file) });
  };

  handlePasswordSelectionConfirm = async (confirmed) => {
    if (!confirmed) {
      this.setState({ showPasswordSelectionModal: false, isFeedbackModalOpen: true, feedbackType: 'ok', feedbackMessage: this.props.t("USERS.EDIT.SuccessMessage") });
      return;
    }

    this.setState({ showPasswordConfirmationModal: true, showPasswordSelectionModal: false });
  };

  handlePasswordConfirmationConfirm = async (confirmed) => {
    if (!confirmed) {
      this.setState({ showPasswordConfirmationModal: false });
      return;
    }

    this.setState({ showPasswordConfirmationModal: false, isFeedbackModalOpen: true, feedbackType: 'ok', feedbackMessage: this.props.t("USERS.EDIT.SuccessMessage") });
  };

  render() {
    const { homes, userTypes, usersData, imageSrc, isUploadModalOpen, showNewPasswordModal, showPasswordSelectionModal, showPasswordConfirmationModal, isLoading, isImageLoading, validate, isFeedbackModalOpen, feedbackType, feedbackMessage, showUpdateConfirmationModal, tags } = this.state;
    const { t } = this.props;
    const buttons = [
      { label: t("USERS.CREATE.SaveButton"), className: styles.saveButton, onClick: this.handleSave },
      { label: t("USERS.CREATE.CancelButton"), className: styles.cancelButton, onClick: () => this.props.onClose(false) }
    ];

    const updateConfirmationButtons = [
      { label: t("USERS.CREATE.SaveButton"), className: styles.saveButton, onClick: this.handleConfirmUpdate },
      { label: t("USERS.CREATE.CancelButton"), className: styles.cancelButton, onClick: this.handleCancelUpdate }
    ];

    return (
      <>
        {isLoading && <LoadingScreen />}
        <Modal title={t("USERS.EDIT.Title")} titleClassName={styles.modalTitle} buttons={buttons}>
          <div className={styles.formUsers}>
            <div className={styles.row}>
              <div className={styles.imageFormHeader}>
                <label className={styles.label}>{t("USERS.CREATE.Image")}</label>
                <span className={styles.editLabel} onClick={this.handleImageClick}>{t("USERS.CREATE.editImage")}</span>
              </div>
              <div className={styles.imageContainer}>
                {isImageLoading ? (
                  <div className={styles.loadingIndicator}>
                    <Icon icon="eos-icons:loading" width="45" height="45" />
                  </div>
                ) : (
                  <img
                    src={imageSrc}
                    alt=""
                    className={styles.image}
                    onMouseEnter={this.handleImageMouseEnter}
                    onMouseLeave={this.handleImageMouseLeave}
                    onClick={this.handleImageClick}
                  />
                )}
              </div>
            </div>
            <div className={styles.row}>
              <label className={styles.label}>{t("USERS.CREATE.User")}<span className={styles.requiredMark}> *</span></label>
              <CustomInput
                type="text"
                name="user"
                value={usersData.user}
                onChange={(value) => this.handleInputChange('user', value)}
                placeholder={t("USERS.CREATE.UserPlaceholder")}
                customClass={styles.inputField}
                validators={[{ regex: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, message: t("USERS.CREATE.ValidationEmail") }]}
                validate={validate}
              />
              <label className={styles.label}>{t("USERS.CREATE.Name")}<span className={styles.requiredMark}> *</span></label>
              <CustomInput
                type="text"
                name="name"
                value={usersData.name}
                onChange={(value) => this.handleInputChange('name', value)}
                placeholder={t("USERS.CREATE.NamePlaceholder")}
                customClass={styles.inputField}
                validators={[{ regex: /.+/, message: t("USERS.CREATE.ValidationRequired") }]}
                validate={validate}
              />
              <label className={styles.label}>{t("USERS.CREATE.Surname")}<span className={styles.requiredMark}> *</span></label>
              <CustomInput
                type="text"
                name="surname"
                value={usersData.surname}
                onChange={(value) => this.handleInputChange('surname', value)}
                placeholder={t("USERS.CREATE.SurnamePlaceholder")}
                customClass={styles.inputField}
                validators={[{ regex: /.+/, message: t("USERS.CREATE.ValidationRequired") }]}
                validate={validate}
              />
            </div>
            <div className={styles.row}>
              <label className={styles.label}>{t("USERS.CREATE.Password")}</label>
              <CustomInput
                type="password"
                name="password"
                value={usersData.password}
                onChange={(value) => this.handleInputChange('password', value)}
                placeholder={t("USERS.CREATE.PasswordPlaceholder")}
                customClass={styles.inputField}
              />
              <label className={styles.label}>{t("USERS.CREATE.Phone")}</label>
              <CustomInput
                type="text"
                name="telephone"
                value={usersData.telephone}
                onChange={(value) => this.handleInputChange('telephone', value)}
                placeholder={t("USERS.CREATE.PhonePlaceholder")}
                customClass={styles.inputField}
              />
              <label className={styles.label}>{t("USERS.CREATE.BirthDate")}</label>
              <CustomInput
                type="date"
                name="birthDate"
                value={usersData.birthDate}
                onChange={(value) => this.handleInputChange('birthDate', value)}
                placeholder={t("USERS.CREATE.BirthDatePlaceholder")}
                customClass={styles.inputField}
              />
            </div>
            <div className={styles.row}>
              <label className={styles.label}>{t("USERS.CREATE.Direction")}</label>
              <CustomInput
                type="text"
                name="direction"
                value={usersData.direction}
                onChange={(value) => this.handleInputChange('direction', value)}
                placeholder={t("USERS.CREATE.DirectionPlaceholder")}
                customClass={styles.inputField}
              />
              <label className={styles.label}>{t("USERS.CREATE.Locality")}</label>
              <CustomInput
                type="text"
                name="locality"
                value={usersData.locality}
                onChange={(value) => this.handleInputChange('locality', value)}
                placeholder={t("USERS.CREATE.LocalityPlaceholder")}
                customClass={styles.inputField}
                validate={validate}
              />
              <label className={styles.label}>{t("USERS.CREATE.Province")}</label>
              <CustomInput
                type="text"
                name="province"
                value={usersData.province}
                onChange={(value) => this.handleInputChange('province', value)}
                placeholder={t("USERS.CREATE.ProvincePlaceholder")}
                customClass={styles.inputField}
              />
              <label className={styles.label}>{t("USERS.CREATE.PostalCode")}</label>
              <CustomInput
                type="text"
                name="postalCode"
                value={usersData.postalCode}
                onChange={(value) => this.handleInputChange('postalCode', value)}
                placeholder={t("USERS.CREATE.PostalCodePlaceholder")}
                customClass={styles.inputField}
              />
            </div>
            <div className={`${styles.row} ${styles.spaceBetween}`}>
              <div className={styles.row}>
                <label className={styles.label}>{t("USERS.CREATE.UserType")}</label>
                <DropdownSelect
                  options={userTypes}
                  isMultiple={false}
                  placeholder={t("USERS.CREATE.SelectUserTypePlaceholder")}
                  setSelectedOptions={this.setSelectedUserType}
                  selectedOptions={this.state.selectedUserType ? [this.state.selectedUserType] : []}
                  customClass={styles.selectUserType}
                  required={true}
                  validate={validate}
                />
              </div>
              <div className={styles.row}>
                <label className={styles.label}>{t("USERS.CREATE.SelectHome")}</label>
                <DropdownSelect
                  options={homes}
                  isMultiple={true}
                  placeholder={t("USERS.CREATE.SelectHomePlaceholder")}
                  setSelectedOptions={this.setSelectedHomes}
                  searchable={true}
                  search={this.searchHomes}
                  selectedOptions={this.state.selectedHomes}
                  customClass={styles.selectHome}
                />
              </div>
            </div>
            <div className={styles.row}>
              <label className={styles.label}>{t("USERS.CREATE.Tags")}</label>
              <TagInput tags={tags} onTagsChange={this.handleTagsChange} />
            </div>
          </div>
        </Modal>

        {isUploadModalOpen && <UploadFileModal onClose={this.closeUploadModal} onFileSelect={this.handleFileSelect} />}

        {showNewPasswordModal && (
          <SendNewPasswordRequest
            onClose={this.handleConfirmSave}
          />
        )}

        {showPasswordSelectionModal && (
          <SendNewPasswordSelection
            userId={this.props.user.id}
            password={usersData.password}
            onClose={this.handlePasswordSelectionConfirm}
          />
        )}

        {showPasswordConfirmationModal && (
          <SendNewPasswordConfirmation
            onClose={this.handlePasswordConfirmationConfirm}
          />
        )}

        {showUpdateConfirmationModal && (
          <CustomModal
            title={t("USERS.EDIT.EditConfirmationTitle")}
            customText={t("USERS.EDIT.EditConfirmationMessage")}
            icon="mdi:account-edit"
            buttons={updateConfirmationButtons}
            onClose={this.handleCancelUpdate}
          />
        )}

        {isFeedbackModalOpen && (
          <ModalFeedback
            type={feedbackType}
            customText={feedbackMessage}
            onClose={this.closeFeedbackModal}
          />
        )}
      </>
    );
  }
}

export default withTranslation()(UpdateUsers);
