import React, { Component } from "react";
import ModalCreateGroup from "./modal-create-group/modalCreateGroup.component";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import styles from "./styles/createGroup.module.scss";
import { createGroup } from '../../../services/GroupService';
import GroupSelectUsers from "./group-select-users/groupSelectUsers.component";
import LoadingScreen from "../../loading-screen/loadingScreen.component";
import ModalFeedback from "../../modal-feedback/modalFeedback.component";
import { addGroup } from '../../../redux/slices/groupSlice';

class CreateGroup extends Component {
    state = {
        isCreateModalOpen: false,
        isSelectUsersModalOpen: false,
        isLoading: false,
        showSuccessModal: false,
        showErrorModal: false,
        errorMessage: "",
        name: "",
        selectedUsers: [],
        guestEmails: [],
    };

    openCreateModal = () => {
        this.setState({ isCreateModalOpen: true });
    };

    closeCreateModal = () => {
        this.setState({
            isCreateModalOpen: false,
            name: "",
            selectedUsers: [],
            guestEmails: [],
        });
    };

    openSelectUsersModal = () => {
        this.setState({ isCreateModalOpen: false, isSelectUsersModalOpen: true });
    };

    closeSelectUsersModal = () => {
        this.setState({ isSelectUsersModalOpen: false, isCreateModalOpen: true });
    };

    handleUsersSubmit = (selectedUsers) => {
        this.setState({
            selectedUsers,
            isSelectUsersModalOpen: false,
            isCreateModalOpen: true,
        });
    };

    handleCreateSubmit = async () => {
        const { name, selectedUsers, guestEmails } = this.state;

        if (!name || (selectedUsers.length === 0 && guestEmails.length === 0)) {
            this.setState({ showErrorModal: true, errorMessage: this.props.t("GROUPS.LABEL.FillAllFields") });
            return;
        }
        const allMembers = [this.props.user.id, ...selectedUsers];
        const groupData = {
            name,
            memberIds: allMembers,
            guestEmails,
        };

        this.setState({ isLoading: true });

        try {
            await createGroup(groupData);
            this.setState({ isLoading: false, showSuccessModal: true });
            this.closeCreateModal();
        } catch (error) {
            console.error("Error creating group:", error);
            this.setState({
                isLoading: false,
                showErrorModal: true,
                errorMessage: this.props.t("GROUPS.LABEL.ErrorCreatingGroup"),
            });
        }
    };

    handleCreateInputChange = (name, value) => {
        this.setState({ [name]: value });
    };

    handleAddEmail = (email) => {
        const { guestEmails } = this.state;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!emailRegex.test(email)) {
            this.setState({ showErrorModal: true, errorMessage: this.props.t("GROUPS.LABEL.InvalidEmail") });
            return;
        }

        if (guestEmails.includes(email)) {
            this.setState({ showErrorModal: true, errorMessage: this.props.t("GROUPS.LABEL.EmailAlreadyAdded") });
            return;
        }

        this.setState({
            guestEmails: [...guestEmails, email],
        });
    };

    handleRemoveEmail = (email) => {
        this.setState((prevState) => ({
            guestEmails: prevState.guestEmails.filter((e) => e !== email),
        }));
    };

    closeSuccessModal = () => {
        this.setState({ showSuccessModal: false });
    };

    closeErrorModal = () => {
        this.setState({ showErrorModal: false, errorMessage: "" });
    };

    render() {
        const {
            isCreateModalOpen,
            isSelectUsersModalOpen,
            selectedUsers,
            name,
            isLoading,
            showSuccessModal,
            showErrorModal,
            errorMessage,
            guestEmails,
        } = this.state;
        const { t } = this.props;

        return (
            <div>
                <button className={styles.createButton} onClick={this.openCreateModal}>
                    {t("GROUPS.LABEL.CreateGroup")}
                </button>

                {isCreateModalOpen && (
                    <ModalCreateGroup
                        onSubmit={this.handleCreateSubmit}
                        onClose={this.closeCreateModal}
                        onOpenSelectUsers={this.openSelectUsersModal}
                        selectedUsers={selectedUsers}
                        name={name}
                        onInputChange={this.handleCreateInputChange}
                        guestEmails={guestEmails}
                        onAddEmail={this.handleAddEmail}
                        onRemoveEmail={this.handleRemoveEmail}
                    />
                )}

                {isSelectUsersModalOpen && (
                    <GroupSelectUsers
                        selectedUsers={selectedUsers}
                        onSubmit={this.handleUsersSubmit}
                        onClose={this.closeSelectUsersModal}
                    />
                )}

                {isLoading && <LoadingScreen />}

                {showSuccessModal && (
                    <ModalFeedback
                        type="ok"
                        title={t("GROUPS.LABEL.SuccessTitle")}
                        customText={t("GROUPS.LABEL.GroupCreatedSuccessfully")}
                        onClose={this.closeSuccessModal}
                    />
                )}

                {showErrorModal && (
                    <ModalFeedback
                        type="error"
                        title={t("GROUPS.LABEL.ErrorTitle")}
                        customText={errorMessage}
                        onClose={this.closeErrorModal}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
});

const mapDispatchToProps = {
    addGroup,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CreateGroup));
