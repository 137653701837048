import React, { Component } from 'react';
import { connect } from 'react-redux';
import NotificationCard from '../notification-card/notificationCard.component';
import NotificationCreate from '../notification-create/notificationCreate.component';
import NotificationDetail from '../notification-detail/notificationDetail.component';
import { withTranslation } from 'react-i18next';
import {
    getNotifications,
    markNotificationAsRead,
} from '../../services/notification/NotificationService';
import {
    setNotifications,
    addNotifications,
    incrementOffset,
    setHasMore,
    markNotificationAsRead as markNotiAsRead,
} from '../../redux/slices/notificationSlice';
import styles from './styles/notificationAll.module.scss';
import { Icon } from '@iconify/react';
import { useNavigate } from 'react-router-dom';
import { hasAdminRole, hasProfesionalRole } from '../../utils/credentials.util';
import NotificationDetailGroup from '../notification-detail-group/notificationDetailGroup.component';

const BackIcon = () => {
    const navigate = useNavigate();

    const handleBackClick = () => {
        navigate(-1);
    };

    return (
        <Icon
            icon="ic:round-arrow-back"
            className={styles.backIcon}
            onClick={handleBackClick}
        />
    );
};

class NotificationAll extends Component {
    state = {
        selectedNotification: null,
        isModalOpen: false,
        isModalGroupOpen: false,
        isLoading: false,
    };

    async componentDidMount() {
        await this.resetNotifications();
        this.loadNotifications();
        this.attachScrollListener();
    }

    componentWillUnmount() {
        this.detachScrollListener();
    }

    resetNotifications = async () => {
        const { setNotifications, incrementOffset, setHasMore, offset } = this.props;
        setNotifications([]);
        incrementOffset(-offset);
        setHasMore(true);
    };

    attachScrollListener = () => {
        if (this.notificationsListRef) {
            this.notificationsListRef.addEventListener('scroll', this.handleScroll);
        }
    };

    detachScrollListener = () => {
        if (this.notificationsListRef) {
            this.notificationsListRef.removeEventListener('scroll', this.handleScroll);
        }
    };

    handleScroll = () => {
        const { hasMore } = this.props;
        const { isLoading } = this.state;

        if (!hasMore || isLoading) return;

        const scrollContainer = this.notificationsListRef;
        const scrollPosition = scrollContainer.scrollTop + scrollContainer.clientHeight;
        const threshold = scrollContainer.scrollHeight - 100;

        if (scrollPosition >= threshold) {
            this.loadNotifications();
        }
    };

    loadNotifications = async () => {
        const { offset, incrementOffset, setHasMore, addNotifications } = this.props;

        this.setState({ isLoading: true });

        try {
            const limit = 15;
            const notifications = await getNotifications(limit, offset);

            if (notifications.length > 0) {
                const newNotifications = notifications.filter(
                    (newNoti) => !this.props.notifications.some((noti) => noti.id === newNoti.id)
                );
                addNotifications(newNotifications);
                incrementOffset(notifications.length);

                if (notifications.length < limit) {
                    setHasMore(false);
                }
            } else {
                setHasMore(false);
            }
        } catch (error) {
            console.error('Error loading notifications:', error);
        } finally {
            this.setState({ isLoading: false });
        }
    };

    openNotificationDetail = async (notification) => {
        try {
            await markNotificationAsRead(notification.id);
            if (notification.groupCallId !== null) {
                this.setState({ selectedNotification: notification, isModalGroupOpen: true });
            } else {
                this.setState({ selectedNotification: notification, isModalOpen: true });
            }
            await this.props.markNotiAsRead(notification.id);
        } catch (error) {
            console.error('Error marking notification as read:', error);
        }
    };

    closeNotificationDetail = () => {
        this.setState({ isModalOpen: false, isModalGroupOpen: false });
    };

    render() {
        const { t, notifications, hasMore } = this.props;
        const { selectedNotification, isModalOpen, isModalGroupOpen, isLoading } = this.state;

        return (
            <div className={styles.notificationAllWrapper}>
                <div className={styles.header}>
                    <BackIcon />
                    {(hasAdminRole() ||hasProfesionalRole()) && (
                        <NotificationCreate />
                    )}
                </div>
                <div
                    className={styles.notificationsList}
                    ref={(ref) => (this.notificationsListRef = ref)}
                >
                    {notifications.length === 0 ? (
                        <p className={styles.emptyNotificationMessage}>
                            {t('NOTIFICATIONS.LABEL.EmptyNotifications')}
                        </p>
                    ) : (
                        notifications.map((notification) => (
                            <NotificationCard
                                key={notification.id}
                                {...notification}
                                withDay={true}
                                onClick={() => this.openNotificationDetail(notification)}
                            />
                        ))
                    )}

                    {isLoading && (
                        <div className={styles.spinnerContainer}>
                            <div className={styles.spinner}></div>
                        </div>
                    )}

                    {!hasMore && notifications.length > 0 && (
                        <p className={styles.noMoreNotifications}>
                            {t('NOTIFICATIONS.LABEL.NoMoreNotifications')}
                        </p>
                    )}
                </div>

                {isModalOpen && (
                    <NotificationDetail
                        notification={selectedNotification}
                        onClose={this.closeNotificationDetail}
                    />
                )}
                {isModalGroupOpen && (
                    <NotificationDetailGroup
                        notification={selectedNotification}
                        onClose={this.closeNotificationDetail}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    notifications: state.notifications.notifications,
    offset: state.notifications.offset,
    hasMore: state.notifications.hasMore,
});

const mapDispatchToProps = {
    setNotifications,
    addNotifications,
    incrementOffset,
    setHasMore,
    markNotiAsRead,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(NotificationAll));
