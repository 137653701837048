import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import styles from './styles/customInput.module.scss';

class CustomInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showPassword: false,
            errors: []
        };
    }

    componentDidMount() {
        const { validate, value } = this.props;
        if (validate) {
            this.validateInput(value);
        }
    }

    componentDidUpdate(prevProps) {
        const { validate, value } = this.props;
        if (validate && (value !== prevProps.value || validate !== prevProps.validate)) {
            this.validateInput(value);
        }
    }

    validateInput = (value) => {
        const { validators, type, min, max } = this.props; 
        const newErrors = [];

        if (type === 'number') {
            const numericValue = parseFloat(value);
            if (isNaN(numericValue)) {
                newErrors.push('Por favor, introduce un número válido.');
            } else {
                if (min !== null && numericValue < min) {
                    newErrors.push(`El valor debe ser mayor o igual a ${min}.`);
                }
                if (max !== null && numericValue > max) {
                    newErrors.push(`El valor debe ser menor o igual a ${max}.`);
                }
            }
        }

        validators.forEach(validator => {
            if (!validator.regex.test(value)) {
                newErrors.push(validator.message);
            }
        });
        this.setState({ errors: newErrors });
    };

    handleChange = (e) => {
        const newValue = e.target.value;
        this.props.onChange(newValue);
    };

    toggleShowPassword = () => {
        this.setState(prevState => ({
            showPassword: !prevState.showPassword
        }));
    };

    getIconByType = () => {
        const { type } = this.props;
        const { showPassword } = this.state;
        if (type === 'password') {
            return showPassword ? 'ic:baseline-remove-red-eye' : 'mingcute:eye-close-line';
        }
        return null;
    };

    render() {
        const { customClass, placeholder, value, type, disabled, min, max } = this.props;
        const { showPassword, errors } = this.state;

        const inputClasses = `${styles.inputField} ${disabled ? styles.disabledInput : ''}`;

        return (
            <div className={`${styles.customInput} ${customClass} ${errors.length > 0 ? styles.error : ''}`}>
                {type === 'textarea' ? (
                    <textarea
                        placeholder={placeholder}
                        value={value || ''}
                        onChange={this.handleChange}
                        className={inputClasses}
                        disabled={disabled}
                    />
                ) : (
                    <input
                        type={type === 'password' && showPassword ? 'text' : type}
                        placeholder={placeholder}
                        value={value || ''}
                        onChange={this.handleChange}
                        className={inputClasses}
                        disabled={disabled}
                        min={type === 'number' ? min : undefined}
                        max={type === 'number' ? max : undefined}
                    />
                )}
                <div className={styles.icon}>
                    {type === 'password' && !disabled && (
                        <Icon 
                            icon={this.getIconByType()} 
                            width="24px" 
                            height="24px" 
                            className={styles.inputIcon} 
                            onClick={this.toggleShowPassword} 
                        />
                    )}
                    {errors.length > 0 && (
                        <div className={styles.tooltip}>
                            <Icon 
                                icon="mingcute:alert-fill" 
                                width="24px" 
                                height="24px" 
                                className={styles.errorIcon} 
                            />
                            <span className={styles.tooltiptext}>
                                {errors.map((error, index) => (
                                    <div key={index} dangerouslySetInnerHTML={{ __html: error }}></div>
                                ))}
                            </span>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

CustomInput.propTypes = {
    customClass: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    validators: PropTypes.arrayOf(PropTypes.shape({
        regex: PropTypes.instanceOf(RegExp).isRequired,
        message: PropTypes.string.isRequired,
    })),
    type: PropTypes.oneOf(['text', 'password', 'date', 'textarea', 'number']).isRequired,
    validate: PropTypes.bool.isRequired,
    disabled: PropTypes.bool,
    min: PropTypes.number,
    max: PropTypes.number,
};

CustomInput.defaultProps = {
    customClass: '',
    placeholder: '',
    type: 'text',
    validate: false,
    validators: [],
    value: '',  
    disabled: false,
    min: null,
    max: null,
};

export default CustomInput;
