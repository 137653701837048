import React, { Component } from "react";
import CustomInput from "../../custom-input/customInput.component";
import SelectButton from "../select-button/selectButton.component";
import styles from "./styles/notificationCreateModal.module.scss";
import { withTranslation } from "react-i18next";
import Modal from "../../modal/modal.component";
import DateTimeInput from "../../date-time-input/dateTimeInput.component";

class NotificationCreateModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sendImmediately: true,
            scheduledDate: '',
            validate: false,
            isSending: false,
            errors: {},
        };
    }

    validateFields = () => {
        const { title, message } = this.props;
        const { sendImmediately, scheduledDate } = this.state;
        const errors = {};

        if (!title) errors.title = this.props.t("NOTIFICATIONS.LABEL.RequiredField");
        if (!message) errors.message = this.props.t("NOTIFICATIONS.LABEL.RequiredField");
        if (!sendImmediately && !scheduledDate) {
            errors.scheduledDate = this.props.t("NOTIFICATIONS.LABEL.RequiredField");
        } else if (!sendImmediately && scheduledDate) {
            const inputDate = new Date(scheduledDate);
            const now = new Date();
            if (inputDate <= now) {
                errors.scheduledDate = this.props.t("NOTIFICATIONS.LABEL.InvalidDate");
            }
        }

        this.setState({ validate: true, errors });

        return Object.keys(errors).length === 0;
    };

    handleSubmit = () => {
        if (this.state.isSending) return;
        this.setState({ isSending: true });

        if (this.validateFields()) {
            const { title, message } = this.props;
            const { sendImmediately, scheduledDate } = this.state;
            const date = sendImmediately ? null : scheduledDate;
            const data = {
                title,
                message,
                date,
            };
            this.props.onSubmit(data);
        } else {
            this.setState({ isSending: false });
        }
    };

    handleCheckboxChange = () => {
        this.setState((prevState) => ({
            sendImmediately: !prevState.sendImmediately,
            scheduledDate: '',
            errors: { ...prevState.errors, scheduledDate: '' },
        }));
    };

    handleDateTimeChange = (value) => {
        this.setState({ scheduledDate: value });
    };

    render() {
        const {
            title,
            message,
            t,
            onClose,
            onOpenSelectUsers,
            onOpenSelectHomes,
            selectedUsers,
            selectedHomes,
            onInputChange,
        } = this.props;
        const { sendImmediately, scheduledDate, validate, errors } = this.state;

        const buttons = [
            {
                label: t("NOTIFICATIONS.LABEL.SendButton"),
                className: styles.confirmButton,
                onClick: this.handleSubmit,
                disabled: this.state.isSending,
            },
            {
                label: t("NOTIFICATIONS.LABEL.CancelButton"),
                className: styles.cancelButton,
                onClick: onClose,
            },
        ];

        return (
            <Modal
                title={t("NOTIFICATIONS.LABEL.Title")}
                titleClassName={styles.modalTitle}
                buttons={buttons}
            >
                <div className={styles.notificationCreateModal}>
                    <div className={styles.description}>
                        {t("NOTIFICATIONS.LABEL.Description")}
                    </div>
                    <div className={styles.inputGroup}>
                        <label htmlFor="title">
                            {t("NOTIFICATIONS.LABEL.TitleQuestion")}
                            <span className={styles.requiredMark}> *</span>
                        </label>
                        <CustomInput
                            type="text"
                            name="title"
                            value={title}
                            onChange={(value) => onInputChange("title", value)}
                            placeholder={t("NOTIFICATIONS.LABEL.TitlePlaceholder")}
                            customClass={styles.inputField}
                            validators={[{ regex: /.+/, message: t("NOTIFICATIONS.LABEL.RequiredField") }]}
                            validate={validate}
                        />

                        <label htmlFor="message">
                            {t("NOTIFICATIONS.LABEL.MessageQuestion")}
                            <span className={styles.requiredMark}> *</span>
                        </label>
                        <CustomInput
                            type="textarea"
                            name="message"
                            value={message}
                            onChange={(value) => onInputChange("message", value)}
                            placeholder={t("NOTIFICATIONS.LABEL.MessagePlaceholder")}
                            customClass={styles.messageField}
                            validators={[{ regex: /.+/, message: t("NOTIFICATIONS.LABEL.RequiredField") }]}
                            validate={validate}
                        />

                        <label htmlFor="users">
                            {t("NOTIFICATIONS.LABEL.UserQuestion")}
                            <span className={styles.requiredMark}> *</span>
                        </label>
                        <div className={styles.buttonGroup}>
                            <SelectButton
                                icon="ph:user-thin"
                                label={t("NOTIFICATIONS.LABEL.SelectUsers")}
                                selectedCount={selectedUsers.length}
                                onClick={onOpenSelectUsers}
                            />
                            <SelectButton
                                icon="mdi:home-outline"
                                label={t("NOTIFICATIONS.LABEL.SelectHomes")}
                                selectedCount={selectedHomes.length}
                                onClick={onOpenSelectHomes}
                            />
                        </div>

                        <div className={styles.sendImmediatelyContainer}>
                            <label className={styles.label}>
                                <input
                                    type="checkbox"
                                    checked={sendImmediately}
                                    onChange={this.handleCheckboxChange}
                                    className={styles.checkbox}
                                />
                                {t("NOTIFICATIONS.LABEL.SendImmediately")}
                            </label>
                        </div>

                        {!sendImmediately && (
                            <div>
                                <label htmlFor="scheduledDate" className={styles.label}>
                                    {t("NOTIFICATIONS.LABEL.ScheduleDate")}
                                    <span className={styles.requiredMark}> *</span>
                                </label>
                                    <DateTimeInput
                                        label={t("NOTIFICATIONS.LABEL.SelectDateTime")}
                                        value={scheduledDate}
                                        onChange={this.handleDateTimeChange}
                                        customClass={styles.dateTimeInput}
                                    />
                                {errors.scheduledDate && (
                                    <span className={styles.error} dangerouslySetInnerHTML={{ __html: errors.scheduledDate }}></span>
                                )}
                            </div>
                        )}


                    </div>
                </div>
            </Modal>
        );
    }
}

export default withTranslation()(NotificationCreateModal);
