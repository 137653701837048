import React, { Component } from "react";
import styles from './styles/tenantProfile.module.scss';
import { withTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { setTenant, setAllowedCalls } from '../../redux/slices/tenantSlice';
import logoTunstall from '../../assets/img/logo_fs_sinfondo_blanco.png';
import { connectUser } from "../../services/UserStateService";
import { getUsersByEmail } from "../../services/UserService";
import { setUserInfo, setUserStatus, setUserRoles } from "../../redux/slices/userSlice";
import { State } from "../../utils/StateEnum";
import { getUserTypes } from "../../services/UserTypeService";
import { setUserTypes } from "../../redux/slices/userTypeSlice";
import { findTenants } from "../../services/TenantService";
import { useNavigate } from 'react-router-dom';
import {jwtDecode} from 'jwt-decode';

class TenantProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tenantsFound: [],
      selectedTenant: null,
      dataReady: false,
    };
    this.navigate = null;
  }

  componentDidMount() {
    if (!this.props.email || !this.props.tenantNames || this.props.tenantNames.length === 0) {
      this.setState({ triedFetchingData: true });
      this.fetchUserDataFromToken();
    } else {
      this.fetchTenants();
      this.props.setUserStatus({ status: State.ONLINE });
    }
  }
  
  componentDidUpdate(prevProps, prevState) {
    if (
      (!prevProps.email && this.props.email) ||
      (prevProps.tenantNames.length === 0 && this.props.tenantNames.length > 0)
    ) {
      this.fetchTenants();
      this.props.setUserStatus({ status: State.ONLINE });
    }
  
    if (!prevState.dataReady && this.state.dataReady) {
      this.props.navigate('/contacts');
    }
  }
  

  fetchImage = (image) => {
    // return await getImage(image);
    return image;
  }

  selectTenant = async (tenant) => {
    try {
      await this.props.setTenant(tenant.name);
      await this.props.setAllowedCalls(tenant.allowedCalls);
      const user = await getUsersByEmail(this.props.email);

      await connectUser(user.id);

      const userTypes = await getUserTypes();
      await this.props.setUserTypes({ userTypes });

      await this.props.setUserInfo({
        email: user.email,
        id: user.id,
        avatar: user.avatar,
      });

      // Establece dataReady en true después de que los datos se hayan establecido
      this.setState({ dataReady: true });
    } catch (error) {
      console.error('Error al seleccionar tenant:', error);
    }
  };

  fetchUserDataFromToken = () => {
    const accessToken = sessionStorage.getItem('accessToken');
    if (accessToken) {
      try {
        const decodedToken = jwtDecode(accessToken);
        const roles = decodedToken.roles;
        this.props.setUserInfo({ email: decodedToken.email });
        this.props.setUserRoles({ roles: roles });
      } catch (error) {
        console.error('Error al decodificar el token:', error);
      }
    } else {
      console.error('No se encontró el token de acceso en sessionStorage');
    }
  };
  

  fetchTenants = async () => {
    const names = this.props.tenantNames;
    var queryNames = "";
    names.forEach(name => queryNames = queryNames + "names=" + name + "&");
    const tenantsFound = await findTenants(queryNames);
    this.setState({ tenantsFound });
    if (tenantsFound.length === 1) {
      this.selectTenant(tenantsFound[0]);
      console.log(JSON.stringify(tenantsFound[0]));
    }
  }

  render() {
    const { t } = this.props;
    const { tenantsFound } = this.state;

    return (
      <>
        <div className={styles.header}>
          <img src={logoTunstall} alt="Logo" className="logo" />
        </div>
        <div className={styles.container}>
          <h3 className={styles.title}>{t('TENANTPROFILE.SelectProfile')}</h3>
          <div className={styles.profileList}>
            {tenantsFound.map((tenant, index) => (
              <div
                key={index}
                className={styles.profile}
                onClick={() => this.selectTenant(tenant)}
              >
                <img src={this.fetchImage(tenant.logo)} alt={tenant.name} className={styles.profileImage} />
                <p>{tenant.desc}</p>
              </div>
            ))}
          </div>
        </div>

        <footer className="login-footer">
          <section className="help-section">
            <p className="help-text">{t('LOGIN.LABEL.FailSession')}</p>
            <p className="help-link">{t('LOGIN.LABEL.Help')}</p>
          </section>
        </footer>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  email: state.user.email,
  tenantNames: state.user.userRoles ? Object.keys(state.user.userRoles) : [],
});

const mapDispatchToProps = {
  setTenant,
  setUserInfo,
  setUserRoles,
  setUserTypes,
  setUserStatus,
  setAllowedCalls,
};

function TenantProfileWithNavigate(props) {
  const navigate = useNavigate();
  return <TenantProfile {...props} navigate={navigate} />;
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TenantProfileWithNavigate));
