import React, { Component } from "react";
import { connect } from "react-redux";
import ContactCard from "../contact-card/contactCard.component.jsx";
import FilterBar from "../filter-bar/filterBar.component.jsx";
import { withTranslation } from 'react-i18next';
import { State } from '../../utils/StateEnum.js';
import styles from './styles/contact.module.scss';
import { Action } from "../../utils/handleActionsEnum.js";
import { setUserHomes } from '../../redux/slices/userSlice';
import { setContacts } from "../../redux/slices/contactSlice";
import { getContacts } from "../../services/UserService.js";

class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contacts: [],
            filteredContacts: [],
            loading: true,
            error: null,
            searchInput: '',
            stateSubscription: null,
            selectedHomes: [],
            selectedUserTypes: []
        };
        this.handleSearch = this.handleSearch.bind(this);
        this.homeHandleChange = this.homeHandleChange.bind(this);
        this.userTypeHandleChange = this.userTypeHandleChange.bind(this);
    }

    async componentDidMount() {
        try {

            const response = await getContacts(false);
            this.props.setContacts(response);
            this.setState({ filteredContacts: response }, this.applyFilters);

        } catch (error) {
            console.error('Error al cargar datos:', error);
            this.setState({ error });
        }
    }
    componentDidUpdate(prevProps) {
        if (prevProps.contacts !== this.props.contacts) {
            this.setState({ filteredContacts: this.props.contacts }, this.applyFilters);
        }
    }

    handleSearch(event) {
        const searchInput = event;
        this.setState({ searchInput }, this.applyFilters);
    }

    clearSearchInput = () => {
        this.setState({ searchInput: '' }, this.applyFilters);
    }

    homeHandleChange = (selectedOptions) => {
        const selectedHomes = selectedOptions.map(option => Number(option.key));
        this.setState({ selectedHomes }, this.applyFilters);
    }

    userTypeHandleChange = (selectedOptions) => {
        const selectedUserTypes = selectedOptions.map(option => Number(option.key));
        this.setState({ selectedUserTypes }, this.applyFilters);
    }

    applyFilters = () => {
        const { searchInput, selectedHomes, selectedUserTypes } = this.state;

        let filteredContacts = this.props.contacts;

        if (searchInput) {
            filteredContacts = filteredContacts.filter(contact => {
                const fullName = `${contact.name ?? ''} ${contact.surname ?? ''}`.toLowerCase();
                return fullName.includes(searchInput.toLowerCase().trim()) ||
                    contact.homes?.some(home => home.niu?.toLowerCase().includes(searchInput.toLowerCase().trim()));
            });
        }

        if (selectedHomes.length > 0) {
            filteredContacts = filteredContacts.filter(contact =>
                contact.homes?.some(home => selectedHomes.includes(home.id))
            );
        }

        if (selectedUserTypes.length > 0) {
            filteredContacts = filteredContacts.filter(contact =>
                selectedUserTypes.includes(contact.userTypeId)
            );
        }

        this.setState({ filteredContacts });
    }

    render() {
        const { t, homes, userTypes } = this.props;
        const { filteredContacts, searchInput, selectedHomes, selectedUserTypes } = this.state;

        const onlineContacts = filteredContacts.filter(contact => contact.state !== State.OFFLINE);
        const offlineContacts = filteredContacts.filter(contact => contact.state === State.OFFLINE);

        return (
            <>
                <div className={styles.filters}>
                    <FilterBar
                        searchInput={searchInput}
                        homes={homes}
                        selectedHomes={selectedHomes}
                        userTypes={userTypes}
                        selectedUserTypes={selectedUserTypes}
                        onSearch={this.handleSearch}
                        onHomeChange={this.homeHandleChange}
                        onUserTypeChange={this.userTypeHandleChange}
                        t={t}
                    />
                </div>
                <div className={styles.contacts}>
                    <div className={styles.list}>
                        {onlineContacts.length > 0 && (
                            <>
                                <h2 className={styles.title}>{t("CONTACTS.LABEL.MyContacts")}</h2>
                                <div className={styles.online}>{t("CONTACTS.LABEL.Online")}</div>
                                <div>
                                    {onlineContacts.map((contact) => (
                                        <ContactCard key={contact.id} contact={contact} onClick={() => this.props.handleActionClick(Action.USER_DETAIL, contact)} />
                                    ))}
                                </div>
                            </>
                        )}
                        {offlineContacts.length > 0 && (
                            <>
                                <div className={styles.all}>{t("CONTACTS.LABEL.All")}</div>
                                <div>
                                    {offlineContacts.map((contact) => (
                                        <ContactCard key={contact.id} contact={contact} onClick={() => this.props.handleActionClick(Action.USER_DETAIL, contact)} />
                                    ))}
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = state => ({
    homes: state.user.homes,
    userTypes: state.userTypes.userTypes,
    contacts: state.contact.contacts,
});

const mapDispatchToProps = dispatch => ({
    setUserHomes: (homes) => dispatch(setUserHomes({ homes })),
    setContacts: (contacts) => dispatch(setContacts(contacts)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Contact));
