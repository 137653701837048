import React from 'react';
import { withTranslation } from 'react-i18next';
import styles from './styles/groupCard.module.scss';
import { Icon } from '@iconify/react';
import ConfirmModal from '../confirm-modal/confirmModal.component';
import ModalEditGroup from '../edit-group/editGroup.component';
import { leaveGroup, updateGroup as updateGroupService } from '../../../services/GroupService';
import { connect } from 'react-redux';
import { updateGroup, removeGroup, setSelectedGroup, assignMemberColors } from '../../../redux/slices/groupSlice';
import { store } from '../../../redux/store';
import { setVideocallInfo } from '../../../redux/slices/videocallSlice';
import { setUserStatus } from '../../../redux/slices/userSlice';
import { useNavigate } from 'react-router-dom';
import { hasAdminRole, hasProfesionalRole } from '../../../utils/credentials.util';

class GroupCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showDropdown: false,
            showLeaveModal: false,
            showEditModal: false,
            showCallModal: false,
            showInLiveCallModal: false,
        };
        this.dropdownRef = React.createRef();
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
        this.assignColorsToMembers();
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleDropdownToggle = (event) => {
        event.stopPropagation();
        this.setState(prevState => ({ showDropdown: !prevState.showDropdown }));
    };

    handleClickOutside = (event) => {
        if (this.dropdownRef.current && !this.dropdownRef.current.contains(event.target)) {
            this.setState({ showDropdown: false });
        }
    };

    assignColorsToMembers = () => {
        const { group, assignMemberColors } = this.props;
        if (!group.memberColors) {
            const memberColors = {};
            group.members.forEach((member, index) => {
                memberColors[member.email] = getRandomColor(index);
            });
            assignMemberColors({ groupId: group.id, memberColors });
        }
    };

    handleEditGroup = (e) => {
        e.stopPropagation();
        this.setState({ showEditModal: true, showDropdown: false });
    };

    handleLeaveGroup = (e) => {
        e.stopPropagation();
        this.setState({ showLeaveModal: true, showDropdown: false });
    };

    closeEditModal = () => {
        this.setState({ showEditModal: false });
    };

    closeLeaveModal = () => {
        this.setState({ showLeaveModal: false });
    };

    handleUpdateGroup = async ({ name, selectedUsers, guestEmails }) => {
        const { group } = this.props;
        try {
            const updatedGroup = await updateGroupService(group.id, { name, memberIds: selectedUsers, guestEmails });
            this.props.updateGroup({ group: updatedGroup });
            this.closeEditModal();
        } catch (error) {
            console.error('Error updating group:', error);
        }
    };

    confirmLeaveGroup = async () => {
        const { group } = this.props;
        try {
            await leaveGroup(group.id);
            this.props.removeGroup({ groupId: group.id });
            this.closeLeaveModal();
        } catch (error) {
            console.error('Error leaving group:', error);
        }
    };

    render() {
        const { group, t } = this.props;
        const { showEditModal, showLeaveModal } = this.state;

        const { name, live, members, content, memberColors } = group;
        const memberCount = members.length;

        const collageMembers = members.slice(0, 4);

        const collageImages = collageMembers.map((user, index) => {
            const initial = user.name
                ? user.name.charAt(0).toUpperCase()
                : user.email.charAt(0).toUpperCase();
            const backgroundColor = memberColors ? memberColors[user.email] : getRandomColor(index);
            return (
                <div
                    key={index}
                    className={styles.collageImage}
                    style={{ backgroundColor }}
                >
                    {initial}
                </div>
            );
        });

        const filteredMembers = members.filter(
            (user) => user.email !== store.getState().user.email
        );
        let memberNamesArray = [
            t("GROUPS.LABEL.You"),
            ...filteredMembers.slice(0, 2).map((user) => user.name || user.email)
        ];

        let memberNames = '';
        if (memberCount === 1) {
            memberNames = memberNamesArray[0];
        } else if (memberCount === 2) {
            memberNames = memberNamesArray.join(` ${t('GROUPS.LABEL.And')} `);
        } else if (memberCount === 3) {
            memberNames = memberNamesArray.slice(0, -1).join(', ') + ` ${t('GROUPS.LABEL.And')} ` + memberNamesArray.slice(-1);
        } else {
            memberNames = memberNamesArray.join(`, `);

            const moreMembers = memberCount > 3 ? ` ${t('GROUPS.LABEL.And')} +${memberCount - 3} ${t('GROUPS.LABEL.More')}` : '';
            memberNames += moreMembers;
        }

        const isContent = !content ? true : false;

        const isLive = live ? 'LIVE' : 'NOTLIVE';

        return (
            <>
                <div className={styles.container} onClick={this.props.onClick}>
                    {isContent ? (<div className={styles.dropdownContainer} ref={this.dropdownRef}>
                        <Icon
                            icon="solar:menu-dots-bold"
                            className={styles.dropIcon}
                            width="24"
                            height="24"
                            onClick={this.handleDropdownToggle}
                        />
                        {this.state.showDropdown && (
                            <div className={styles.dropdownMenu}>
                                {(hasAdminRole() || hasProfesionalRole()) && (
                                    <button onClick={this.handleEditGroup}>
                                        <Icon icon="akar-icons:edit" className={styles.iconButton} width="20" height="20" />
                                        {t('GROUPS.LABEL.Edit')}
                                    </button>
                                )}
                                <button onClick={this.handleLeaveGroup}>
                                    <Icon icon="fluent:people-subtract-20-regular" className={styles.iconButton} width="20" height="20" />
                                    {t('GROUPS.LABEL.Leave')}
                                </button>
                            </div>
                        )}
                    </div>
                    ) : null}

                    <div className={styles.content}>
                        <div className={`${styles.imageCollage} ${styles[`members-${collageMembers.length}`]}`}>
                            {collageImages}
                        </div>
                        <div className={styles.groupInfo}>
                            <div className={styles.groupName}>{name}</div>
                            <div className={styles.memberCount}>
                                {t('GROUPS.LABEL.Members_plural', { count: memberCount })}
                            </div>
                            <div className={styles.memberPreview}>
                                {memberNames}
                            </div>
                        </div>
                    </div>

                    <div className={styles.status}>
                        {live && <span className={styles.liveIndicator}></span>}
                        {t(`GROUPS.STATUS.${isLive}`)}
                    </div>
                </div>

                {showEditModal && (
                    <ModalEditGroup
                        group={group}
                        onSubmit={this.handleUpdateGroup}
                        onClose={this.closeEditModal}
                    />
                )}

                {showLeaveModal && (
                    <ConfirmModal
                        title={t('GROUPS.LABEL.LeaveGroup')}
                        message={t('GROUPS.LABEL.ConfirmLeave')}
                        onConfirm={this.confirmLeaveGroup}
                        onCancel={this.closeLeaveModal}
                    />
                )}
            </>
        );
    }
}

function getRandomColor(index) {
    const colors = ['#00B4E6', '#4169E1', '#1E90FF', '#5A9CFD', '#0F6BA8'];
    return colors[index % colors.length];
}

const mapStateToProps = (state) => ({
    userId: state.user.id,
    email: state.user.email,
});

const mapDispatchToProps = {
    updateGroup,
    removeGroup,
    setVideocallInfo,
    setUserStatus,
    setSelectedGroup,
    assignMemberColors,
};

function GroupCardWithNavigate(props) {
    const navigate = useNavigate();
    return <GroupCard {...props} navigate={navigate} />;
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(GroupCardWithNavigate));
