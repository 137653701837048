import {store} from '../redux/store.js';
import {errorHandler} from '../utils/errorHandler.js';

const API_URL = process.env.REACT_APP_BACKEND_API_URL;
const token = sessionStorage.getItem('accessToken');
const UserStateService = {

  makeRequest: async (userId, endpoint) => {
    if (!userId) {
      console.error("Error: User ID no está definido");
    }

    const tenantName = store.getState().tenant.tenant;
    try {
      const response = await fetch(`${API_URL}/api/v1/user-states/${userId}/${endpoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Tenant-ID': `${tenantName}`,
          'Authorization': `Bearer ${token}`
        }
      });

      errorHandler(response.status);
    } catch (error) {
      console.error(`Error en la solicitud de ${endpoint}:`, error);
    }
  },

  connectUser: async (userId) => {
    await UserStateService.makeRequest(userId, 'connect');
  },
  disconnectUser: async (userId) => {
    await UserStateService.makeRequest(userId, 'disconnect');
  },
  busyUser: async (userId) => {
    await UserStateService.makeRequest(userId, 'busy');
  },
  changeUserStateToStartCall: async (userId) => {
    await UserStateService.makeRequest(userId, 'start-call');
  },
  changeUserStateToEndCall: async (userId) => {
    await UserStateService.makeRequest(userId, 'end-call');
  }
}


export const { connectUser, disconnectUser, busyUser, changeUserStateToStartCall, changeUserStateToEndCall } = UserStateService;