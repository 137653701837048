// redux/slices/groupsSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    groups: [],
    selectedGroup: null,
};

const groupsSlice = createSlice({
    name: 'groups',
    initialState,
    reducers: {
        setGroups(state, action) {
            state.groups = action.payload.groups;
        },
        addGroup(state, action) {
            state.groups.push(action.payload.group);
        },
        updateGroup(state, action) {
            const index = state.groups.findIndex(group => group.id === action.payload.group.id);
            if (index !== -1) {
                state.groups[index] = action.payload.group;
            }
        },
        removeGroup(state, action) {
            state.groups = state.groups.filter(group => group.id !== action.payload.groupId);
        },
        clearGroups(state) {
            state.groups = [];
        },
        setSelectedGroup(state, action) {
            state.selectedGroup = action.payload;
        },
        clearSelectedGroup(state) {
            state.selectedGroup = null;
        },
        assignMemberColors(state, action) {
            const { groupId, memberColors } = action.payload;
            const group = state.groups.find(g => g.id === groupId);
            if (group) {
                group.memberColors = memberColors;
            }
        },
    },
});

export const {
    setGroups,
    addGroup,
    updateGroup,
    removeGroup,
    clearGroups,
    setSelectedGroup,
    clearSelectedGroup,
    assignMemberColors,
} = groupsSlice.actions;
export default groupsSlice.reducer;
