import {store} from '../redux/store.js';
import {errorHandler} from '../utils/errorHandler.js';

const API_URL = process.env.REACT_APP_BACKEND_API_URL;
const token = sessionStorage.getItem('accessToken');

const UserService = {
    getContacts: async (withLastCall) => {
        const tenantName = store.getState().tenant.tenant;
        try {
            const response = await fetch(API_URL + `/api/v1/users/contacts?withLastCallInfo=${withLastCall}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Tenant-ID': `${tenantName}`,
                    'Authorization': `Bearer ${token}`
                }
            });

            errorHandler(response.status);

            if (response.ok) {
                return response.json();
            }
            return [];
        } catch (error) {
            console.error('Error fetching Contacts:', error);
            throw error;
        }
    },
    getContactsByUserId: async (userId) => {
        const tenantName = store.getState().tenant.tenant;
        try {
            const response = await fetch(API_URL + '/api/v1/users/contacts/' + userId.toString(), {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Tenant-ID': `${tenantName}`,
                    'Authorization': `Bearer ${token}`
                }
            });

            errorHandler(response.status);

            if (response.ok) {
                return response.json();
            }
            return [];
        } catch (error) {
            console.error('Error fetching Contacts:', error);
            throw error;
        }
    },
    searchUsers: async (term, active) => {
        const tenantName = store.getState().tenant.tenant;
        try {
            let url = `${API_URL}/api/v1/users/search?term=${encodeURIComponent(term)}`;
            if (active !== undefined) {
                url += `&active=${active}`;
            }

            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Tenant-ID': `${tenantName}`,
                    'Authorization': `Bearer ${token}`
                }
            });

            errorHandler(response.status);

            if (response.ok) {
                return await response.json();
            }
            return [];
        } catch (error) {
            console.error('Error fetching Users:', error);
            throw error;
        }
    },
    getHomesByUsers: async (userIds) => {
        const tenantName = store.getState().tenant.tenant;
        if (userIds === undefined || userIds.length === 0) return [];
        try {
            const response = await fetch(API_URL + '/api/v1/users/' + userIds.toString() + '/homes', {
                method: 'GET',
                headers: {
                    'X-Tenant-ID': `${tenantName}`,
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
            });

            errorHandler(response.status);

            if (response.ok) {
                return response.json();
            }
            return [];
        } catch (error) {
            console.error('Error fetching Users:', error);
            throw error;
        }
    },
    getUsersById: async (userId) => {
        const tenantName = store.getState().tenant.tenant;
        try {
            const response = await fetch(API_URL + '/api/v1/users/' + userId.toString(), {
                method: 'GET',
                headers: {
                    'X-Tenant-ID': `${tenantName}`,
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
            });

            errorHandler(response.status);

            if (response.ok) {
                return response.json();
            }
            return [];
        } catch (error) {
            console.error('Error fetching Homes:', error);
            throw error;
        }
    },
    getUsersByEmail: async (email) => {
        const tenantName = store.getState().tenant.tenant;
        try {
            const response = await fetch(API_URL + '/api/v1/users/email?email=' + email, {
                method: 'GET',
                headers: {
                    'X-Tenant-ID': `${tenantName}`,
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
            });

            errorHandler(response.status);

            if (response.ok) {
                return response.json();
            }
            return [];
        } catch (error) {
            console.error('Error fetching Homes:', error);
            throw error;
        }
    },
    changeStatus: async (id) => {
        const tenantName = store.getState().tenant.tenant;
        try {
            const response = await fetch(API_URL + '/api/v1/users/change-status/' + id, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Tenant-ID': `${tenantName}`,
                    'Authorization': `Bearer ${token}`
                }
            });

            errorHandler(response.status);

            if (response.ok) {
                return response.json();
            }
            return [];
        } catch (error) {
            console.error('Error fetching Users:', error);
            throw error;
        }
    },
    changeAdmin: async (id) => {
        const tenantName = store.getState().tenant.tenant;
        try {
            const response = await fetch(API_URL + '/api/v1/users/change-admin/' + id, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Tenant-ID': `${tenantName}`,
                    'Authorization': `Bearer ${token}`
                }
            });

            errorHandler(response.status);

            if (response.ok) {
                return response.json();
            }
            return [];
        } catch (error) {
            console.error('Error fetching Users:', error);
            throw error;
        }
    },
    changeModerator: async (id) => {
        const tenantName = store.getState().tenant.tenant;
        try {
            const response = await fetch(API_URL + '/api/v1/users/change-moderator/' + id, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Tenant-ID': `${tenantName}`,
                    'Authorization': `Bearer ${token}`
                }
            });

            errorHandler(response.status);

            if (response.ok) {
                return response.json();
            }
            return [];
        } catch (error) {
            console.error('Error fetching Users:', error);
            throw error;
        }
    },
    createUser: async (data) => {
        const tenantName = store.getState().tenant.tenant;
        const formData = new FormData();
        formData.append('file', data.file);
        formData.append('user', new Blob([JSON.stringify({
            email: data.email,
            name: data.name,
            alias: data.name,
            surname: data.surname,
            password: data.password,
            telephone: data.phone,
            birthDate: data.birthDate,
            direction: data.direction,
            locality: data.locality,
            province: data.province,
            postalCode: data.postalCode,
            userTypeId: data.userTypeId,
            homeIds: data.homeIds,
            active: data.active,
            moderator: data.moderator,
            tags: data.tags,
        })], { type: "application/json" }));

        try {
            const response = await fetch(`${API_URL}/api/v1/users`, {
                method: 'POST',
                headers: {
                    'X-Tenant-ID': `${tenantName}`,
                    'Authorization': `Bearer ${token}`
                },
                body: formData
            });

            errorHandler(response.status);

            if (response.ok) {
                return await response.json();
            } else {
                const errorResponse = await response.json();
                console.error('Error response:', errorResponse);
                throw new Error(`Error creating user: ${errorResponse.message}`);
            }
        } catch (error) {
            console.error('Error:', error.message);
            throw new Error('Error creating user');
        }
    },
    updateUser: async (data) => {
        const tenantName = store.getState().tenant.tenant;
        const formData = new FormData();

        if (data.file instanceof File) {
            formData.append('file', data.file);
        }

        const userBlob = new Blob([JSON.stringify({
            id: data.id,
            email: data.email || null,
            name: data.name || null,
            alias: data.name || null,
            surname: data.surname || null,
            password: data.password || null,
            telephone: data.phone || null,
            birthDate: data.birthDate || null,
            direction: data.direction || null,
            locality: data.locality || null,
            province: data.province || null,
            postalCode: data.postalCode || null,
            userTypeId: data.userTypeId || null,
            homeIds: data.homeIds || [],
            tags: data.tags || [],
            active: data.active !== undefined ? data.active : null,
            moderator: data.moderator !== undefined ? data.moderator : null,
            avatar: data.file instanceof File ? null : data.file
        })], { type: "application/json" });
        formData.append('user', userBlob);

        try {
            const response = await fetch(API_URL + '/api/v1/users/' + data.id.toString(), {
                method: 'PUT',
                headers: {
                    'X-Tenant-ID': `${tenantName}`,
                    'Authorization': `Bearer ${token}`
                },
                body: formData
            });

            errorHandler(response.status);

            if (response.ok) {
                return response.json();
            } else {
                const errorResponse = await response.json();
                console.error('Error response:', errorResponse);
                throw new Error(`Error updating user: ${errorResponse.message}`);
            }
        } catch (error) {
            console.error('Error fetching User:', error);
            throw error;
        }
    },
    updateUserByUser: async (data) => {
        const tenantName = store.getState().tenant.tenant;
        const formData = new FormData();

        if (data.file instanceof File) {
            formData.append('file', data.file);
        }

        const userBlob = new Blob([JSON.stringify({
            email: data.email || null,
            name: data.name || null,
            alias: data.name || null,
            surname: data.surname || null,
            password: data.password || null,
            telephone: data.phone || null,
            birthDate: data.birthDate || null,
            direction: data.direction || null,
            locality: data.locality || null,
            province: data.province || null,
            postalCode: data.postalCode || null,
            userTypeId: data.userTypeId || null,
            homeIds: data.homeIds || null,
            avatar: data.file instanceof File ? null : data.file
        })], { type: "application/json" });
        formData.append('user', userBlob);

        try {
            const response = await fetch(API_URL + '/api/v1/users', {
                method: 'PATCH',
                headers: {
                    'X-Tenant-ID': `${tenantName}`,
                    'Authorization': `Bearer ${token}`
                },
                body: formData
            });

            errorHandler(response.status);

            if (response.ok) {
                return response.json();
            } else {
                const errorResponse = await response.json();
                console.error('Error response:', errorResponse);
                throw new Error(`Error updating user: ${errorResponse.message}`);
            }
        } catch (error) {
            console.error('Error updating user:', error);
            throw error;
        }
    },
    deleteUser: async (userId) => {
        const tenantName = store.getState().tenant.tenant;
        try {
            const response = await fetch(API_URL + '/api/v1/users/' + userId.toString(), {
                method: 'DELETE',
                headers: {
                    'X-Tenant-ID': `${tenantName}`,
                    'Authorization': `Bearer ${token}`
                },
            });

            errorHandler(response.status);

            if (response.ok) {
                return;
            }
            return [];
        } catch (error) {
            console.error('Error fetching User:', error);
            throw error;
        }
    },
    sendPasswords: async (ownerId, password, userIds) => {
        const tenantName = store.getState().tenant.tenant;
        try {
            const response = await fetch(API_URL + '/api/v1/users/send-passwords', {
                method: 'POST',
                headers: {
                    'X-Tenant-ID': `${tenantName}`,
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    userId: ownerId,
                    password: password,
                    contactIds: userIds
                })
            });

            errorHandler(response.status);
        } catch (error) {
            console.error('Error sending passwords:', error);
            throw error;
        }
    },
    getUsersByIds: async (userIds) => {
      const tenantName = store.getState().tenant.tenant;

      const url = `${API_URL}/api/v1/users/list-users?ids=${userIds.join(",")}`;

      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'X-Tenant-ID': `${tenantName}`,
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          errorHandler(response.status);
          return [];
        }

        return await response.json();
      } catch (error) {
        console.error('Error fetching users by IDs:', error);
        throw error;
      }
    }
}

export const { getContacts, searchUsers, getHomesByUsers, getUsersById, getUsersByEmail, changeStatus, changeModerator, changeAdmin, createUser, updateUser, updateUserByUser, deleteUser, getUserTypes, getContactsByUserId, sendPasswords,getUsersByIds } = UserService;
