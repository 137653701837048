import React, { Component, useState, useEffect, useRef } from "react";
import styles from "./styles/homesTable.module.scss";
import { withTranslation } from 'react-i18next';
import { Icon } from '@iconify/react';
import { t } from "i18next";
import { hasAdminRole } from "../../../utils/credentials.util";

const HomeRow = ({ item, onStatusChange, onEdit, onDelete, hasManagementPermissions }) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const dropdownRef = useRef(null);

    const handleDropdownToggle = () => {
        setShowDropdown(!showDropdown);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setShowDropdown(false);
        }
    };

    useEffect(() => {
        if (showDropdown) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showDropdown]);

    return (
        <tr>
            <td>
                <div className={styles.homeTitle}>{item.name}</div>
                <div className={styles.homeId}>{item.niu}</div>
            </td>
            <td>{item.address}</td>
            <td>{item.locality}</td>
            <td>{item.province}</td>
            <td>{item.postalCode}</td>
            <td className={styles.status}>
                <div className={styles.statusGroup}>
                    <div className={styles.statusText}><span>{item.active ? t("HOMES.LABEL.Activated") : t("HOMES.LABEL.Deactivated")}</span></div>
                    {hasManagementPermissions && (<label className={styles.switch}>
                        <input
                            type="checkbox"
                            checked={item.active}
                            onChange={() => onStatusChange(item.id, !item.active)}
                        />
                        <span className={`${styles.slider} ${styles.round}`}></span>
                    </label>
                    )}
                </div>
            </td>
            {hasManagementPermissions && (
                <td>
                    <div className={styles.dropdownContainer} ref={dropdownRef}>
                        <Icon icon="solar:menu-dots-bold" className={styles.dropIcon} width="20" height="20" onClick={handleDropdownToggle} />
                        {showDropdown && (
                            <div className={styles.dropdownMenu}>
                                <button onClick={() => { onEdit(item); setShowDropdown(false); }}>
                                    <Icon icon="akar-icons:edit" className={styles.iconButton} width="20" height="20" />{t("HOMES.LABEL.Edit")}
                                </button>
                                <button onClick={() => { onDelete(item.id); setShowDropdown(false); }}>
                                    <Icon icon="gravity-ui:trash-bin" className={styles.iconButton} width="20" height="20" />{t("HOMES.LABEL.Delete")}
                                </button>
                            </div>
                        )}
                    </div>
                </td>
            )}
        </tr>
    );
};

class HomesTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            sortAscending: true,
            sortKey: 'name',
            sortedData: [],
        };
    }

    componentDidMount() {
        this.setState({ sortedData: this.props.homeData });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.homeData !== this.props.homeData) {
            this.setState({ sortedData: this.props.homeData });
        }
        if (prevProps.scrollToTop !== this.props.scrollToTop) {
            if (this.props.scrollToTop === true) {
                const element = document.getElementById("homesTable")
                if (element) {
                    element.scroll({
                        top: 0,
                        behavior: "smooth"
                    });
                }
                this.props.onScrollToTop();
            }
        }
    }

    sortData = (data) => {
        const { sortAscending, sortKey } = this.state;
        const sorted = [...data].sort((a, b) => {
            const aValue = a[sortKey] ? a[sortKey].toString().toLowerCase() : '';
            const bValue = b[sortKey] ? b[sortKey].toString().toLowerCase() : '';
            if (aValue < bValue) return sortAscending ? -1 : 1;
            if (aValue > bValue) return sortAscending ? 1 : -1;
            return 0;
        });
        this.setState({ sortedData: sorted });
    }

    handleSort = () => {
        this.setState((prevState) => ({
            sortAscending: !prevState.sortAscending
        }), () => {
            this.sortData(this.props.homeData);
        });
    };

    hasManagementPermissions() {
        return hasAdminRole()
    }

    render() {
        const { t, onStatusChange, onEdit, onDelete } = this.props;
        const { sortedData } = this.state;

        return (
            <div id="homesTable" className={`${styles.tableContainer}`}>
                <table className={styles.homeTable}>
                    <thead>
                        <tr>
                            <th onClick={this.handleSort} className={styles.sorting}>
                                <Icon icon="mdi:compare-vertical" width="20" height="20" />
                                {t('HOMES.TABLE.NIU')}
                            </th>
                            <th>{t('HOMES.TABLE.Address')}</th>
                            <th>{t('HOMES.TABLE.Locality')}</th>
                            <th>{t('HOMES.TABLE.Province')}</th>
                            <th>{t('HOMES.TABLE.PostalCode')}</th>
                            <th>{t('HOMES.TABLE.Status')}</th>
                            {this.hasManagementPermissions() && (<th></th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {sortedData.map((item) => (
                            <HomeRow
                                key={item.id}
                                item={item}
                                onStatusChange={onStatusChange}
                                onEdit={onEdit}
                                onDelete={onDelete}
                                hasManagementPermissions={this.hasManagementPermissions()}
                            />
                        ))}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default withTranslation()(HomesTable);
