import React from 'react';
import Modal from '../../modal/modal.component';
import styles from './styles/confirmModal.module.scss';
import { withTranslation } from 'react-i18next';

const ConfirmModal = ({ title, message, onConfirm, onCancel, t }) => {
    const buttons = [
        {
            label: t('GROUPS.LABEL.ConfirmButton'),
            className: styles.confirmButton,
            onClick: onConfirm,
        },
        {
            label: t('GROUPS.LABEL.CancelButton'),
            className: styles.cancelButton,
            onClick: onCancel,
        },
    ];

    return (
        <Modal
            title={title}
            buttons={buttons}
            titleClassName={styles.modalTitle}
        >
            <div className={styles.confirmMessage}>
                {message}
            </div>
        </Modal>
    );
};

export default withTranslation()(ConfirmModal);
