import { store } from '../redux/store';

const getUserRolesFromState = () => {
    const state = store.getState();
    const { userRoles } = state.user || {};
    const { tenant } = state.tenant || {};

    if (!userRoles || !tenant) {
        return '';
    }
    return userRoles[tenant] || '';
};

const hasUserRole = () => {
    const role = getUserRolesFromState();
    return role === 'user';
};

const hasAdminRole = () => {
    const role = getUserRolesFromState();
    return role === 'admin';
};

const hasProfesionalRole = () => {
    const role = getUserRolesFromState();
    return role === 'professional';
};

const hasFamiliarRole = () => {
    const role = getUserRolesFromState();
    return role === 'family';
};

const checkRoleInAllowedCalls = (role) => {
    const state = store.getState();
    let userRole = getUserRolesFromState();
    userRole = userRole.charAt(0).toUpperCase() + userRole.slice(1);
    const { allowedCalls } = state.tenant || {};
    return allowedCalls[userRole]?.includes(role) || false;
};

const hasManagerRole = () => {
    const role = getUserRolesFromState();
    return role === 'manager';
};

export { hasUserRole, hasAdminRole, hasProfesionalRole, hasFamiliarRole, hasManagerRole, checkRoleInAllowedCalls };
