import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles/tagInput.module.scss';
import Tag from '../tag/tag.component';

const TagInput = ({ tags = [], onTagsChange }) => {
    const { t } = useTranslation();
    const [inputValue, setInputValue] = useState('');

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleInputKeyDown = (e) => {
        if (e.key === 'Enter' && inputValue.trim()) {
            if (!tags.includes(inputValue.trim().toUpperCase())) {
                const newTags = [...tags, inputValue.trim().toUpperCase()];
                onTagsChange(newTags);
            }
            setInputValue('');
        } else if (e.key === 'Backspace' && !inputValue && tags.length > 0) {
            const newTags = tags.slice(0, -1);
            onTagsChange(newTags);
        }
    };

    const handleTagRemove = (tag) => {
        const newTags = tags.filter((t) => t !== tag);
        onTagsChange(newTags);
    };

    return (
        <div className={styles.tagInputContainer}>
            <div className={styles.tags}>
                {tags.map((tag, index) => (
                    <Tag
                        key={index}
                        tag={tag}
                        onRemove={handleTagRemove}
                        showRemoveButton={true}
                    />
                ))}
            </div>
            <input
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                onKeyDown={handleInputKeyDown}
                placeholder={t('USERS.TAGS.Placeholder')}
                className={styles.tagInput}
            />
        </div>
    );
};

export default TagInput;
