import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { store } from "../../redux/store";
import { setSelectedComponent, setSelectedLayout } from '../../redux/slices/layoutSlice';
import UsersView from '../users-view/usersView.component';
import { hasAdminRole, hasManagerRole, hasProfesionalRole } from '../../utils/credentials.util';

const UsersWrapper = () => {
    useEffect(() => {
        store.dispatch(setSelectedComponent('users'));
        store.dispatch(setSelectedLayout('vertical'));
    }, []);

    const hasAccessToProtectedRoutes = hasAdminRole() || hasProfesionalRole() || hasManagerRole();

    if (!hasAccessToProtectedRoutes) {
        return <Navigate to="/contacts" replace />;
    }

    return <UsersView />;
};

export default UsersWrapper;
