import React, { Component, createRef } from 'react';
import styles from './styles/agendaView.module.scss';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

class AgendaView extends Component {
    constructor(props) {
        super(props);
        this.todayRef = createRef();
        this.state = {
            days: this.getDaysInMonth(props.currentDate)
        };
    }

    componentDidMount() {
        if (this.todayRef.current) {
            this.todayRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.currentDate !== this.props.currentDate || prevProps.events !== this.props.events) {
            this.setState({ days: this.getDaysInMonth(this.props.currentDate) }, () => {
                if (this.todayRef.current) {
                    this.todayRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }
            });
        }
    }

    getTotalDate = (date) => {
        return new Date(date.getFullYear(), date.getMonth(), date.getDate());
    }

    getDaysInMonth = (date) => {
        const { language, timeZone } = this.props;
        const year = date.getFullYear();
        const month = date.getMonth();
        const daysInMonth = new Date(year, month + 1, 0).getDate();
        const days = Array.from({ length: daysInMonth }, (_, i) => new Date(year, month, i + 1));
        return days.map(day => {
            const truncatedDay = this.getTotalDate(day);
            const dayEvents = this.props.events.filter(event =>
                truncatedDay >= this.getTotalDate(new Date(event.start)) && truncatedDay <= this.getTotalDate(new Date(event.end))
            );

            return {
                date: day.getDate().toString().padStart(2, '0'),
                month: day.toLocaleString(language, { month: 'long', timeZone }),
                day: day.toLocaleString(language, { weekday: 'long', timeZone }),
                events: dayEvents.map(event => {
                    let time;
                    if (event.isAllDay) {
                        const isFirstDay = truncatedDay.getTime() === this.getTotalDate(new Date(event.start)).getTime();
                        const isLastDay = truncatedDay.getTime() === this.getTotalDate(new Date(event.end)).getTime();
                        if (isFirstDay) {
                            time = `${new Date(event.start).toLocaleTimeString(language, { hour: '2-digit', minute: '2-digit', timeZone })} - 20:00`;
                        } else if (isLastDay) {
                            time = `08:00 - ${new Date(event.end).toLocaleTimeString(language, { hour: '2-digit', minute: '2-digit', timeZone })}`;
                        } else {
                            time = this.props.t("CALENDAR.LABEL.AllDay");
                        }
                    } else {
                        time = `${new Date(event.start).toLocaleTimeString(language, { hour: '2-digit', minute: '2-digit', timeZone })} - ${new Date(event.end).toLocaleTimeString(language, { hour: '2-digit', minute: '2-digit', timeZone })}`;
                    }
                    return {
                        time,
                        title: event.title,
                        icon: event.icon,
                        color: event.backgroundColor,
                        category: event.categoryName,
                    };
                })
            };
        });
    }

    isToday = (date) => {
        const today = new Date();
        return date === today.getDate() &&
               this.props.currentDate.getMonth() === today.getMonth() &&
               this.props.currentDate.getFullYear() === today.getFullYear();
    }

    render() {
        const { t } = this.props;
        const { days } = this.state;
        return (
            <div className={styles.agendaView}>
                <table className={styles.agendaTable}>
                    <thead>
                        <tr>
                            <th>{t("CALENDAR.LABEL.DAY")}</th>
                            <th>{t("CALENDAR.LABEL.HOUR")}</th>
                            <th>{t("CALENDAR.LABEL.EVENT")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {days.map((day, dayIndex) => (
                            <tr key={dayIndex}
                                ref={this.isToday(parseInt(day.date)) ? this.todayRef : null}>
                                <td className={`${styles.dateHeader} ${this.isToday(parseInt(day.date)) ? styles.today : ''}`}>
                                    <div className={styles.dateCircle}>{day.date}</div>
                                    <div>
                                        <div className={styles.month}>{day.month.charAt(0).toUpperCase() + day.month.slice(1)}</div>
                                        <div className={styles.dayName}>{day.day.charAt(0).toUpperCase() + day.day.slice(1)}</div>
                                    </div>
                                </td>
                                <td>
                                    {day.events.length > 0 ? (
                                        <ul className={styles.eventTimeList}>
                                            {day.events.map((event, index) => (
                                                <li key={index} className={styles.eventTime} style={{ '--event-color': event.color }}>
                                                    {event.time}
                                                </li>
                                            ))}
                                        </ul>
                                    ) : (
                                        <div className={styles.noEvents}>{t("CALENDAR.LABEL.NoEventsAgenda")}</div>
                                    )}
                                </td>
                                <td className={styles.agendaRow}>
                                    {day.events.length > 0 ? (
                                        <ul className={styles.eventList}>
                                            {day.events.map((event, index) => (
                                                <li key={index} className={styles.eventItem} style={{ color: event.color }}>
                                                    {event.icon && <span className={styles.eventIcon}>{event.icon}</span>}
                                                    <span className={styles.eventCategory}>{t('CATEGORY.' + event.category)}.&nbsp;</span>
                                                    <span className={styles.eventTitle}>{event.title}</span>
                                                </li>
                                            ))}
                                        </ul>
                                    ) : null}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    language: state.language.language,
    timeZone: state.timeZone.timeZone,
});

export default connect(mapStateToProps)(withTranslation()(AgendaView));
