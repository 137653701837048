import React, { Component } from "react";
import HomesTable from "./homes-table/homesTable.component";
import { withTranslation } from 'react-i18next';
import CreateHome from "./create-home/createHome.component";
import UpdateHome from "./update-home/updateHome.component";
import DeleteHome from "./delete-home/deleteHome.component";
import ModalConfirmation from '../modal-confirmation/modalConfirmation.component';
import styles from "./styles/homesView.module.scss";
import { Icon } from '@iconify/react';
import { searchHomes, changeStatus } from '../../services/HomeService';
import { connect } from 'react-redux';
import { hasAdminRole } from '../../utils/credentials.util';

const mapStateToProps = (state) => ({
    userRoles: state.user.userRoles || []
});

class HomesView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchQuery: "",
            homeData: [],
            loading: false,
            showCreateHome: false,
            showUpdateHome: false,
            showDeleteHome: false,
            showCreateConfirmationModal: false,
            homeToUpdate: null,
            homeToDelete: null,
            scrollToTop: false
        };
    }

    handleSearchChange = (event) => {
        this.setState({ searchQuery: event.target.value }, this.fetchData);
    };

    handleStatusChange = async (id, newStatus) => {
        try {
            await changeStatus(id);
            this.setState(prevState => ({
                homeData: prevState.homeData.map(home =>
                    home.id === id ? { ...home, active: newStatus } : home
                )
            }));
        } catch (error) {
            console.error('Error changing status:', error);
        }
    };

    fetchData = async () => {
        this.setState({ loading: true, error: null });
        const { searchQuery } = this.state;

        try {
            const data = await searchHomes(searchQuery);
            this.setState({ homeData: data, loading: false });
        } catch (error) {
            this.setState({ loading: false });
        }
    };

    componentDidMount() {
        this.fetchData();
    }

    handleShowCreateHome = () => {
        this.setState({ showCreateHome: true });
    };

    handleCloseCreateHome = async (toFetch) => {
        if (toFetch) {
            this.setState({ searchQuery: '' });
            await this.fetchData();
            this.setState({ showCreateConfirmationModal: true });
        }
        this.setState({ showCreateHome: false });
    };

    handleCloseCreateConfirmationModal = () => {
        this.setState({ showCreateConfirmationModal: false });
    };

    handleShowUpdateHome = async (home) => {
        this.setState({ homeToUpdate: home, showUpdateHome: true });
    };

    handleCloseUpdateHome = async (toFetch) => {
        if (toFetch) {
            this.setState({ searchQuery: '' });
            await this.fetchData();
        }
        this.setState({ showUpdateHome: false, homeToUpdate: null });
    };

    handleShowDeleteHome = (id) => {
        this.setState({ homeToDelete: id, showDeleteHome: true });
    };

    handleCloseDeleteHome = async (toFetch) => {
        if (toFetch) {
            this.setState({ searchQuery: '' });
            await this.fetchData();
        }
        this.setState({ showDeleteHome: false, homeToDelete: null });
    };

    makeScroll = () => {
        this.setState({ scrollToTop: true });
    };

    resetScroll = () => {
        this.setState({ scrollToTop: false });
    };

    hasManagementPermissions() {
        return hasAdminRole()
    }

    render() {
        const { t } = this.props;
        const { searchQuery, homeData, loading, showCreateHome, showUpdateHome, homeToUpdate, showDeleteHome, homeToDelete, scrollToTop, showCreateConfirmationModal } = this.state;

        return (
            <div className={styles.container}>
                <h1>{t('HOMES.LABEL.Title')}</h1>
                <div className={styles.searchAndButton}>
                    <div className={styles.searchContainer}>
                        <Icon icon="lucide:search" width="18px" height="18px" className={styles.searchIcon} />
                        <input
                            type="text"
                            placeholder={t('HOMES.LABEL.SearchBar')}
                            value={searchQuery}
                            onChange={this.handleSearchChange}
                            className={styles.searchBar}
                        />
                    </div>
                    {this.hasManagementPermissions() && (
                        <button className={styles.addHomeButton} onClick={this.handleShowCreateHome}>
                            {t('HOMES.LABEL.AddHome')}
                        </button>
                    )}
                </div>
                {!loading && (
                    <HomesTable
                        homeData={homeData}
                        onStatusChange={this.handleStatusChange}
                        onEdit={(home) => this.handleShowUpdateHome(home)}
                        onDelete={(id) => this.handleShowDeleteHome(id)}
                        onScrollToTop={this.resetScroll}
                        scrollToTop={scrollToTop}
                    />
                )}
                {showCreateHome && <CreateHome onClose={this.handleCloseCreateHome} />}
                {showUpdateHome && <UpdateHome home={homeToUpdate} onClose={(toFetch) => this.handleCloseUpdateHome(toFetch)} />}
                {showDeleteHome && <DeleteHome homeId={homeToDelete} onClose={this.handleCloseDeleteHome} />}

                {showCreateConfirmationModal && (
                    <ModalConfirmation
                        title={t("HOMES.CREATE.SuccessTitle")}
                        customText={t("HOMES.CREATE.SuccessMessage")}
                        icon="mdi:home-plus"
                        onClose={this.handleCloseCreateConfirmationModal}
                    />
                )}
                <div className={styles.scrollToTopButton} onClick={this.makeScroll}>
                    <Icon icon="icon-park-outline:up" width="30px" height="30px" />
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps)(withTranslation()(HomesView));
