import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './styles/tagTooltip.module.scss';
import Tag from '../tag/tag.component';
import { Icon } from '@iconify/react/dist/iconify.js';


const TagTooltip = ({ tags }) => {
    const [showTooltip, setShowTooltip] = useState(false);

    const handleMouseEnter = () => setShowTooltip(true);
    const handleMouseLeave = () => setShowTooltip(false);

    return (
        <div
            className={styles.tags}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            {tags[0] && <Tag tag={tags[0]} showRemoveButton={false} />}
            {tags.length > 1 && (
                <div
                    className={`${styles.moreTagsContainer} ${showTooltip ? styles.active : ''
                        }`}
                >
                    <Icon
                        icon={
                            showTooltip
                                ? 'mingcute:up-fill'
                                : 'mingcute:down-fill'
                        }
                        className={styles.icon}
                        width="20"
                        height="20"
                    />
                </div>
            )}
            {tags.length > 1 && showTooltip && (
                <div className={styles.tooltip}>
                    {tags.slice(1).map((tag, index) => (
                        <Tag key={index} tag={tag} showRemoveButton={false} />
                    ))}
                </div>
            )}
        </div>
    );
};

TagTooltip.propTypes = {
    tags: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default TagTooltip;