import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import Contact from '../contact/contact.component';
import { Action } from "../../utils/handleActionsEnum";
import { Icon } from '@iconify/react';
import styles from './styles/menuFamiliar.module.scss';
import { setSelectedComponent } from '../../redux/slices/layoutSlice';
import { clearSelectedContact } from '../../redux/slices/contactSlice';
import { Outlet, useNavigate } from "react-router-dom";
import Groups from "../groups-view/groups.component";

const menu = [
    {
        key: 'contact',
        icon: 'fluent:book-contacts-20-regular',
        label: 'MENU.LABEL.Contacts',
        action: Action.CONTACTS,
    },
    {
        key: 'groups',
        icon: 'material-symbols:groups-outline-rounded',
        label: 'MENU.LABEL.Groups',
        action: Action.GROUPS,
    },
];

class IconWithLabel extends Component {
    render() {
        const { icon, label, onClick, menuItemKey, selectedComponent } = this.props;
        const iconClass = selectedComponent === menuItemKey ? `${styles.iconWithLabel} ${styles.selected}` : styles.iconWithLabel;

        return (
            <div className={iconClass} onClick={onClick}>
                <Icon icon={icon} width="30" height="30" />
                <div className={styles.label}>{label}</div>
            </div>
        );
    }
}

class MenuFamiliar extends Component {
    constructor(props) {
        super(props);
        const savedComponent = this.props.selectedComponent || 'contact';
        const savedLayout = this.props.layout || 'horizontal';
        this.state = {
            selectedComponent: savedComponent,
            layout: savedLayout,
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.selectedComponent !== prevProps.selectedComponent) {
            console.log("Selected component changed to: ", this.props.selectedComponent);
            this.setState({ selectedComponent: this.props.selectedComponent });
        }
        if (this.props.layout !== prevProps.layout) {
            console.log("Layout changed to: ", this.props.layout);
            this.layout = styles[this.props.layout];
            this.setState({ layout: this.props.layout });
        }
        if (this.props.selectedMenuComponent !== prevProps.selectedMenuComponent) {
            this.setState({ selectedComponent: this.props.selectedMenuComponent });
        }
    }

    handleIconClick = (key, action, handleActionClick, onLayoutChange) => {
        if (action === Action.CONTACTS) {
            if (window.location.pathname === '/videocall') {
                this.props.navigate('/contacts');
            } else {
                this.props.setSelectedComponent('contact');
            }
        }
        if (action === Action.GROUPS) {
            if (window.location.pathname === '/videocall') {
                this.props.navigate('/groups');
            } else {
                this.props.setSelectedComponent('groups');
            }
        }
        handleActionClick(action, null);
    }

    render() {
        const { t, handleActionClick, onLayoutChange, selectedComponent} = this.props;

        const componentMap = {
            'contact': <Contact handleActionClick={handleActionClick} />,
            'groups': <Groups handleActionClick={handleActionClick} />,
            'outlet': <div className={styles.mainContainer}><Outlet style={{ flex: 1, width: '100%', height: '100%' }} /></div>,
        };

        const filteredMenu = menu.map((option) => ({
            ...option,
        }));

        return (
            <div className={styles.rootContainer}>
                <nav className={`${styles.navigation} ${styles[this.props.layout]}`}>
                    {filteredMenu.map((option) => (
                        <IconWithLabel
                            key={option.key}
                            icon={option.icon}
                            label={t(option.label)}
                            onClick={() => this.handleIconClick(option.key, option.action, handleActionClick, onLayoutChange)}
                            menuItemKey={option.key}
                            selectedComponent={selectedComponent}
                        />
                    ))}
                </nav>
                <div className={styles.componentDisplay}>
                    {componentMap[selectedComponent] || null}
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    setSelectedComponent: (component) => dispatch(setSelectedComponent(component)),
    clearSelectedContact: () => dispatch(clearSelectedContact()),
});

const mapStateToProps = state => ({
    layout: state.layout.selectedLayout || 'horizontal',
    selectedComponent: state.layout.selectedComponent || 'contact',
    selectedContact: state.contact.selectedContact,
    selectedGroup: state.groups.selectedGroup,
});

function MenuFamiliarWithNavigate(props) {
    const navigate = useNavigate();
    return <MenuFamiliar {...props} navigate={navigate} />;
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(MenuFamiliarWithNavigate));
