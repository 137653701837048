import React, { Component } from "react";
import { Icon } from '@iconify/react';
import styles from './styles/helpSection.module.scss';
import { withTranslation } from "react-i18next";
import Modal from "../../modal/modal.component";

class HelpSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sections: {
                termsAndConditions: false,
                privacyPolicy: false,
                cookiePolicy: false,
                supportContact: false,
            },
            showModal: false,
            modalContent: "",
            modalTitle: ""
        };
    }

    toggleSection = (section) => {
        this.setState((prevState) => ({
            sections: {
                ...prevState.sections,
                [section]: !prevState.sections[section]
            }
        }));
    }

    openModal = (title, content) => {
        this.setState({
            showModal: true,
            modalContent: content,
            modalTitle: title
        });
    }

    closeModal = () => {
        this.setState({
            showModal: false,
            modalContent: "",
            modalTitle: ""
        });
    }

    render() {
        const { t } = this.props;
        const { sections, showModal, modalContent, modalTitle } = this.state;

        return (
            <div className={styles.helpSection}>
                <div className={styles.title}>{t("HELP.TITLE.About")}</div>
                <table className={styles.table}>
                    <tbody>
                        {this.renderRow("TermsAndConditions", "termsAndConditions", sections.termsAndConditions, t("HELP.CONTENT.terms&condition"), t("HELP.LABEL.TermsTitle"))}
                        {this.renderRow("PrivacyPolicy", "privacyPolicy", sections.privacyPolicy, t("HELP.CONTENT.privacyPolicy"), t("HELP.LABEL.PrivacyTitle"))}
                        {this.renderRow("CookiePolicy", "cookiePolicy", sections.cookiePolicy, t("HELP.CONTENT.cookiePolicy"), t("HELP.LABEL.CookieTitle"))}
                        {this.renderRow("SupportContact", "supportContact", sections.supportContact, t("HELP.CONTENT.supportContact"), t("HELP.LABEL.SupportTitle"))}
                    </tbody>
                </table>

                {showModal && (
                    <Modal
                        title={modalTitle}
                        onClose={this.closeModal}
                        children={<div className={styles.content} dangerouslySetInnerHTML={{ __html: modalContent }} />}
                        buttons={[
                            {
                                label: t("HELP.BUTTON.close"),
                                className: styles.closeButton,
                                onClick: this.closeModal
                            }
                        ]}
                    />
                )}
            </div>
        );
    }

    renderRow(labelKey, stateKey, isOpen, content, title) {
        const { t } = this.props;

        return (
            <>
                <tr className={styles.tableRow} onClick={() => this.toggleSection(stateKey)}>
                    <td className={styles.tableCell}>
                        <label className={styles.label}>{t(`HELP.LABEL.${labelKey}`)}</label>
                    </td>
                    <td className={styles.tableCell}>
                        <Icon icon="mingcute:right-line" className={`${styles.icon} ${isOpen ? styles.open : ''}`} />
                    </td>
                </tr>
                {isOpen && (
                    <tr className={styles.tableRowContent}>
                        <td className={styles.tableCellContent} colSpan="2">
                            <button
                                type="button"
                                className={styles.showButton}
                                title={title}
                                onClick={() => this.openModal(title, content)}
                            >
                                <Icon icon="carbon:view-filled"  />
                                {t("HELP.BUTTON.show")}
                            </button>
                        </td>
                    </tr>
                )}
            </>
        );
    }
}

export default withTranslation()(HelpSection);
