import React, { useEffect, useState } from 'react';
import './styles/App.scss';
import Router from './Routes/Router.jsx';
import 'bootstrap/dist/css/bootstrap.min.css';
import { I18nextProvider } from 'react-i18next';
import i18n from 'i18next';
import { useSelector } from 'react-redux';
import { askNotificationPermission, onMessageListener, requestToken } from './services/notification/FirebaseService';
import ModalEnableNotifications from './components/modal-enable-notifications/modalEnableNotifications.component';
const worker = new Worker(new URL('./worker/worker.js', import.meta.url));

// Configuración de i18n
i18n.init({
  interpolation: { escapeValue: false },
  lng: 'es',
  resources: {
    en: { translation: require('./i18n/en.json') },
    es: { translation: require('./i18n/es.json') },
  },
});

function App() {
  const language = useSelector((state) => state.language.language);
  const session = useSelector((state) => state.session.session);
  const tenant = useSelector((state) => state.tenant?.tenant);
  const userState = useSelector((state) => state.user.userStatus);

  const [showEnableNotificationsModal, setShowEnableNotificationsModal] = useState(false);

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  // Inicializar notificaciones push
  useEffect(() => {
    const initNotifications = async () => {
      const permissionGranted = await askNotificationPermission();
      console.log("Permiso de notificaciones:", permissionGranted);
      if (permissionGranted) {
        await requestToken();
      } else {
        setShowEnableNotificationsModal(true);
      }
    };

    if (tenant && !session) {
      initNotifications();
    }

    if (session) {
      onMessageListener()
        .then(async (payload) => {
          console.log("Mensaje recibido en primer plano:", payload);
        })
        .catch((err) => console.error('Error recibiendo notificación:', err));
    }
  }, [tenant, session]);

  // Registro del Service Worker para Firebase
  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('./firebase-messaging-sw.js')
        .then((registration) => {
          console.log('Service Worker registrado:', registration);
        })
        .catch((err) => {
          console.error('Error al registrar el Service Worker:', err);
        });

      if (!navigator.serviceWorker.messageListenerAdded) {
        navigator.serviceWorker.addEventListener('message', (event) => {
          if (event.data && event.data.type === 'NEW_NOTIFICATION') {
            console.log('Notificación recibida desde el SW:', event.data.payload);
          }
        });

        navigator.serviceWorker.messageListenerAdded = true;
      }
    }
  }, []);

  useEffect(() => {
    const tokenRefreshInterval = setInterval(() => {
      requestToken().then(token => {
        if (token) {
          console.log('Token actualizado:', token);
        }
      });
    }, 30 * 60 * 1000); // 30 minutos

    return () => clearInterval(tokenRefreshInterval);
  }, []);

  // Para actualizar los valores de tenant y userState en los pings sin reconectar
  useEffect(() => {
    if (tenant && userState) {
      worker.postMessage({
        type: 'UPDATE_PING_DATA',
        data: { tenant, userState },
      });
    }
  }, [tenant, userState]);

  // Manejar el cambio de visibilidad de la pestaña
  useEffect(() => {
    const handleVisibilityChange = () => {
      worker.postMessage({
        type: 'VISIBILITY_CHANGE',
        data: {
          isVisible: document.visibilityState === 'visible',
        },
      });
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return (
    <I18nextProvider i18n={i18n}>
      <main>
        <Router />

        {showEnableNotificationsModal && (
          <ModalEnableNotifications onClose={() => {
            setShowEnableNotificationsModal(false);
          }} />
        )}
      </main>
    </I18nextProvider>
  );
}

export default App;
