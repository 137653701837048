import React from 'react';
import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom';
import { store } from '../redux/store';
import { checkAuth } from '../services/auth/AuthService';
import LoginWrapper from '../pages/login/index';
import Dashboard from '../pages/dashboard';
import RecoveryPassword from '../pages/recovery-password';
import ModifyPassword from '../pages/modify-password';
import TenantProfile from '../components/tenant-profile/tenantProfile.component';
import NotificationAll from '../components/notification-all/notificationAll.component';
import UsersWrapper from '../components/wrappers/UsersWrapper';
import ProfileWrapper from '../components/wrappers/ProfileWrapper';
import ContactsWrapper from '../components/wrappers/ContactsWrapper';
import HomesWrapper from '../components/wrappers/HomesWrapper';
import ContentWrapper from '../components/wrappers/ContentWrapper';
import VideoCallWrapper from '../components/wrappers/VideoCallWrapper';
import GroupsWrapper from '../components/wrappers/GroupsWrapper';
import CalendarWrapper from '../components/wrappers/CalendarWrapper';
import VideocallInvitationWrapper from '../components/wrappers/VideocallInvitationWrapper';
const ProtectedRoute = ({ element }) => {
    const isAuthenticated = checkAuth();
    const hasTenant = !!store.getState().tenant.tenant;

    if (!isAuthenticated) {
        return <Navigate to="/login" replace />;
    }
    if (!hasTenant) {
        return <Navigate to="/tenants" replace />;
    }
    return element;
};

const TenantRoute = ({ element }) => {
    const isAuthenticated = checkAuth();
    const hasTenant = !!store.getState().tenant.tenant;

    if (!isAuthenticated) {
        return <Navigate to="/login" replace />;
    }
    if (hasTenant) {
        return <Navigate to="/contacts" replace />;
    }
    return element;
};

const router = createBrowserRouter([
    {
        path: '/',
        element: <ProtectedRoute element={<Dashboard />} />,
        children: [
            { path: 'contacts', element: <ContactsWrapper /> },
            { path: 'videocall', element: <VideoCallWrapper /> },
            { path: 'groups', element: <GroupsWrapper /> },
            { path: 'profile', element: <ProfileWrapper /> },
            { path: 'calendar', element: <CalendarWrapper /> },
            { path: 'notifications', element: <NotificationAll /> },
            { path: 'users', element: <UsersWrapper /> },
            { path: 'homes', element: <HomesWrapper /> },
            { path: 'content', element: <ContentWrapper /> },
            { path: '*', element: <Navigate to="contacts" /> },
        ],
    },
    {
        path: '/tenants',
        element: <TenantRoute element={<TenantProfile />} />,
    },
    {
        path: '/login',
        element: <LoginWrapper />,
    },
    {
        path: '/video-call-invitation',
        element: <VideocallInvitationWrapper />,
    },
    {
        path: '/recovery-password',
        element: <RecoveryPassword />,
    },
    {
        path: '/modify-password',
        element: <ModifyPassword />,
    },
    {
        path: '*',
        element: <Navigate to={checkAuth() ? '/contacts' : '/login'} replace />,
    },
]);

const Router = () => {
    return <RouterProvider router={router} />;
};

export default Router;
