import React from 'react';
import { useSearchParams } from 'react-router-dom';
import VideocallInvitation from '../../pages/videocall-invitation/videocallInvitation.component';
const VideocallInvitationWrapper = () => {
    const [searchParams] = useSearchParams();
    const call = searchParams.get('call');
    const dates = searchParams.get('dates');
    
    return <VideocallInvitation call={call} dates={dates} />;
};

export default VideocallInvitationWrapper;