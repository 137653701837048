import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
import Modal from '../modal/modal.component';
import { Icon } from '@iconify/react';
import styles from './styles/modalBlockNavigation.module.scss';

class ModalBlockNavigation extends Component {

  render() {
    const { t, onClose } = this.props;

    const buttons = [
      { label: t("NAVIGATION.ConfirmButton"), className: styles.confirmButton, onClick: () => onClose(true) },
      { label: t("NAVIGATION.CancelButton"), className: styles.cancelButton, onClick: () => onClose(false) }
    ];

    const icon = <Icon icon="bxs:error" className={styles.icon} />;

    return (
      <>
        <Modal title={t("NAVIGATION.Title")} titleClassName={styles.modalTitle} buttons={buttons} image={icon} onClose={() => onClose(false)}>
            <p className={styles.messages}>{t("NAVIGATION.Message")}</p>
        </Modal>
      </>
    );
  }
}

export default withTranslation()(ModalBlockNavigation);
