import React from "react";
import Modal from "../modal/modal.component";
import { calculateTimeDifference, getFormattedTimeFromTimestamp } from "../../utils/date.util";
import { Icon } from "@iconify/react";
import { withTranslation } from 'react-i18next';
import styles from './styles/notificationDetailGroup.module.scss';
import ButtonJoinCallNotification from '../groups-view/button-join-call-notification/buttonJoinCallNotification.component';

class NotificationDetail extends React.Component {
  render() {
    const { notification, onClose, t } = this.props;
    const { title, body, date } = notification;

    return (
      <Modal
        title={title}
        titleClassName={styles.modalTitle}
        onClose={onClose}
        image={<Icon icon="weui:video-call-filled" width="40px" height="40px" className={styles.notificationIcon} />}
      >
        <div className={styles.notificationDetailContent}>
          <div className={styles.notificationBody}>{body}</div>
          <div className={styles.notificationTime}>
            <span>{calculateTimeDifference(date, t)}</span>
            <Icon icon="tabler:point-filled" width="10" height="10" />
            <span>{getFormattedTimeFromTimestamp(date)}</span>
          </div>
          <div className={styles.buttonGroup}>
            <ButtonJoinCallNotification
              groupId={notification.groupCallId}
              onClose={onClose}
            />
            <button
              type="button"
              className={styles.cancelButton}
              title={t('GROUPS.LABEL.CancelButton')}
              onClick={onClose}
            >
              {t('GROUPS.LABEL.CancelButton')}
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}

export default withTranslation()(NotificationDetail);
