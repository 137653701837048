import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    selectedLayout: null,
    selectedComponent: null,
    selectedMenuComponent: null,
};

const layoutSlice = createSlice({
    name: 'layout',
    initialState,
    reducers: {
        setSelectedLayout(state, action) {
            state.selectedLayout = action.payload;
        },
        clearSelectedLayout(state) {
            state.selectedLayout = null;
        },
        setSelectedComponent(state, action) {
            state.selectedComponent = action.payload;
        },
        clearSelectedComponent(state) {
            state.selectedComponent = null;
        },
        setSelectedMenuComponent(state, action) {
            state.selectedMenuComponent = action.payload;
        },
        clearSelectedMenuComponent(state) {
            state.selectedMenuComponent = null;
        },
    },
});

export const { setSelectedLayout, clearSelectedLayout, setSelectedComponent, clearSelectedComponent, setSelectedMenuComponent, clearSelectedMenuComponent } = layoutSlice.actions;
export default layoutSlice.reducer;
