import {store} from '../redux/store';

export const calculateTimeDifference = (notificationTime, t) => {
    const timeZone = store.getState().timeZone.timeZone;
    const language = store.getState().language.language || 'es-ES';

    const notificationDate = new Date(notificationTime);
    const currentDate = new Date();

    const differenceInSeconds = Math.floor((currentDate - notificationDate) / 1000);
    const differenceInMinutes = Math.floor(differenceInSeconds / 60);
    const differenceInHours = Math.floor(differenceInMinutes / 60);
    const differenceInDays = Math.floor(differenceInHours / 24);

    if (differenceInDays > 2) {
        const options = {year: 'numeric', month: 'numeric', day: 'numeric', timeZone};
        const formattedDate = new Intl.DateTimeFormat(language, options).format(notificationDate);
        return t(`TIMEAGO.Received`, {formattedDate});
    } else if (differenceInDays > 0) {
        return t(`TIMEAGO.daysAgo`, {count: differenceInDays}) + (differenceInDays !== 1 ? 's' : '');
    } else if (differenceInHours > 0) {
        return t(`TIMEAGO.hoursAgo`, {count: differenceInHours}) + (differenceInHours !== 1 ? 's' : '');
    } else if (differenceInMinutes > 0) {
        return t(`TIMEAGO.minutesAgo`, {count: differenceInMinutes}) + (differenceInMinutes !== 1 ? 's' : '');
    } else {
        return t(`TIMEAGO.momentsAgo`);
    }
};

export const getFormattedTimeFromTimestamp = (timestamp) => {
    const timeZone = store.getState().timeZone.timeZone;
    const language = store.getState().language.language || 'es-ES';

    const date = new Date(timestamp);
    const options = {hour: '2-digit', minute: '2-digit', timeZone, hour12: false};
    return new Intl.DateTimeFormat(language, options).format(date) + 'h';
};

export const getFormattedTimeFromLocalTime = (timestamp) => {
    const timeZone = store.getState().timeZone.timeZone;
    const language = store.getState().language.language || 'es-ES';
    const date = new Date();

    if (timestamp != null && timestamp !== undefined) {
        const [hours, minutes] = timestamp.split(':');
        date.setHours(hours);
        date.setMinutes(minutes);
    }

    const options = {hour: 'numeric', minute: 'numeric', timeZone, hour12: false};
    return new Intl.DateTimeFormat(language, options).format(date);
};

export const getCurrentDate = (notificationTime, t) => {
    const timeZone = store.getState().timeZone.timeZone;
    const language = store.getState().language.language || 'es-ES';

    const notificationDate = new Date(notificationTime);
    const day = notificationDate.getDate();
    const options = {month: 'long', timeZone};
    const formattedMonth = new Intl.DateTimeFormat(language, options).format(notificationDate);

    return `${day} ${t('MONTHS.of')} ${formattedMonth}`;
};
