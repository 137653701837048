import React, {Component} from "react";
import styles from './styles/sideCalendar.module.scss';
import {Icon} from "@iconify/react";
import {getCategories, getContentByUserAndDate} from "../../../services/ContentService";
import {mapCategory} from "../../../utils/CategoryMapper.util";
import {withTranslation} from 'react-i18next';
const ID_ACTIVIDADES_GRUPO = 4;
const ID_LIVES_YOUTUBE = 6;
class SideCalendar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            categories: [],
            events: [],
            showEvents: false
        };
    }

    componentDidMount() {
        this.loadCategories();
        this.loadEvents();
    }

    async loadCategories() {
        try {
            let categories = await getCategories();
            categories = categories.filter(category => category.id === ID_ACTIVIDADES_GRUPO || category.id === ID_LIVES_YOUTUBE);
            this.setState({ categories });
        } catch (error) {
            console.error("Error fetching categories:", error);
        }
    }

    async loadEvents() {
        try {
            const startDate = new Date();
            startDate.setHours(0, 0, 0, 0);
            const endDate = new Date();
            endDate.setHours(23, 59, 59, 999);
            const categoryIds = [ID_ACTIVIDADES_GRUPO, ID_LIVES_YOUTUBE];
            const events = await getContentByUserAndDate(startDate, endDate, undefined, undefined, categoryIds);
            this.setState({ events });
        } catch (error) {
            console.error("Error fetching events:", error);
        }
    }

    toggleEvents = () => {
        this.setState((prevState) => ({
            showEvents: !prevState.showEvents
        }));
    }

    formatDate = (date) => {
        const { language } = this.props;
        const month = date.toLocaleDateString(language, { month: 'long' });
        const year = date.getFullYear();
        return `${month.charAt(0).toUpperCase() + month.slice(1)} ${year}`;
    }

    isAllDayEvent = (startDate, endDate) => {
        const start = new Date(startDate);
        const end = new Date(endDate);
        return start.getDate() !== end.getDate() || start.getMonth() !== end.getMonth() || start.getFullYear() !== end.getFullYear();
    }

    render() {
        const { categories, events, showEvents } = this.state;
        const { currentDate, onPrev, onNext, t, language, timeZone } = this.props;

        return (
            <div className={styles.side}>
                <div className={styles.header}>
                    <Icon icon="mingcute:down-line" className={styles.navButtonLeft} onClick={() => onPrev('month')} />
                    <span className={styles.month}>{this.formatDate(currentDate)}</span>
                    <Icon icon="mingcute:down-line" className={styles.navButtonRight} onClick={() => onNext('month')} />
                </div>
                <div className={styles.sideCalendar}>
                    <div className={styles.today} onClick={this.toggleEvents}>
                        <div>
                            <span className={styles.todayLabel}>{t("CALENDAR.LABEL.Today")}</span>
                            <span className={styles.date}>{new Date().toLocaleDateString(language)}</span>
                        </div>
                        <Icon icon="mingcute:down-line" className={`${styles.toggleIcon} ${showEvents ? styles.open : ''}`} />
                    </div>
                    {showEvents && (
                        <div className={styles.eventsList}>
                            {events.length > 0 ? (
                                <ul>
                                    {events.map((event, index) => {
                                        const startDate = new Date(event.startDate);
                                        const endDate = new Date(event.endDate);
                                        const startTime = startDate.toLocaleTimeString(language, { hour: '2-digit', minute: '2-digit', timeZone });
                                        const endTime = endDate.toLocaleTimeString(language, { hour: '2-digit', minute: '2-digit', timeZone });

                                        const isAllDayEvent = this.isAllDayEvent(startDate, endDate);

                                        const mappedCategory = mapCategory(event.category.name);
                                        return mappedCategory ? (
                                            <li
                                                className={styles.eventItem}
                                                key={index}
                                                style={{ '--category-color': mappedCategory.color }}
                                            >
                                                <span className={styles.time}>
                                                    {isAllDayEvent ? t("CALENDAR.LABEL.AllDay") : `${startTime} - ${endTime}`}
                                                </span>
                                                <Icon icon="fluent:video-16-filled" className={styles.iconVideo} />
                                                <span className={styles.description}>{event.description}</span>
                                                <a href={event.resource} className={styles.link}>({event.resource.substring(0, 15) + "..."})</a>
                                            </li>
                                        ) : null;
                                    })}
                                </ul>
                            ) : (
                                <div className={styles.noEventsMessage}>
                                    {t("CALENDAR.LABEL.NoEvents")}
                                </div>
                            )}
                        </div>
                    )}
                    <div className={styles.channels}>
                        <div className={styles.channelTitle}>{t("CALENDAR.LABEL.Channels")}</div>
                        <ul className={styles.channelList}>
                            {categories.map((category, index) => {
                                const mappedCategory = mapCategory(category.name);
                                return mappedCategory ? (
                                    <li
                                        className={styles.channel}
                                        key={index}
                                        style={{ '--category-color': mappedCategory.color }}
                                    >
                                        <span className={styles.name}>
                                            {t('CATEGORY.' + mappedCategory.name)}
                                        </span>
                                        {mappedCategory.icon}
                                    </li>
                                ) : null;
                            })}
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(SideCalendar);
