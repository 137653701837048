import React, { Component } from 'react';
import Calendar from '@toast-ui/react-calendar';
import './styles/toastUiCalendar.scss';
import { getContentByUserAndDate, patchContentOccurrence } from "../../../services/ContentService";
import { mapCategory } from "../../../utils/CategoryMapper.util";
import styles from './styles/customCalendar.module.scss';
import { Icon } from '@iconify/react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import AgendaView from '../agenda-view/agendaView.component';
import Modal from '../../modal/modal.component';
import LiveToggleButton from '../live-toggle-button/liveToggleButton.component';
import EditContentModal from '../edit-content-modal/editContentModal.component';
import { IconButton, Tooltip } from '@mui/material';
import { hasAdminRole, hasManagerRole, hasProfesionalRole } from '../../../utils/credentials.util';


class CustomCalendar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            allEvents: [],
            filteredEvents: [],
            hasAllDayEvents: false,
            showModal: false,
            selectedEvent: null,
            showEditModal: false,
            editedStartDate: null,
            editedEndDate: null,
            isSubmitting: false,
            error: null,
        };
    }

    componentDidMount() {
        this.loadEventsForMonth();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.currentDate !== this.props.currentDate || prevProps.view !== this.props.view) {
            this.loadEventsForMonth();
        }
    }

    async loadEventsForMonth() {
        try {
            const { currentDate } = this.props;
            const startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1, 0, 0, 0, 0);
            const endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0, 23, 59, 59, 999);
            const categoryIds = [4, 6];
            const eventsData = await getContentByUserAndDate(startDate, endDate, undefined, undefined, categoryIds);
            console.log("Events data:", eventsData);
            let hasAllDay = false;
            const allEvents = eventsData.map(event => {
                const start = new Date(event.startDate);
                const end = new Date(event.endDate);
                const isMultiDay = start.getDate() !== end.getDate() || start.getMonth() !== end.getMonth() || start.getFullYear() !== end.getFullYear();
                const categoryInfo = mapCategory(event.content.category.name);
                if (isMultiDay) {
                    hasAllDay = true;
                }

                return {
                    id: String(event.id),
                    calendarId: '1',
                    title: event.content.name,
                    category: isMultiDay ? 'allday' : 'time',
                    categoryName: event.content.category.name,
                    start: new Date(event.startDate),
                    end: new Date(event.endDate),
                    backgroundColor: categoryInfo ? categoryInfo.color : '#000000',
                    isReadOnly: true,
                    isAllDay: isMultiDay,
                    icon: categoryInfo ? categoryInfo.icon : null,
                    raw: {
                        resource: event.content.resource,
                        startDate: event.startDate,
                        endDate: event.endDate,
                        live: event.live,
                        groupId: event.content.groupId,
                        content: event.content,
                        isRecurrent: event.content.recurrent,
                    },
                };
            });
            this.setState({ allEvents, hasAllDayEvents: hasAllDay }, this.filterEvents);
        } catch (error) {
            console.error("Error fetching events:", error);
        }
    }

    filterEvents = () => {
        const { allEvents } = this.state;
        const { currentDate, view } = this.props;
        let filteredEvents = [];
        let hasAllDayEvents = false;

        if (view === 'month') {
            filteredEvents = allEvents.map(event => ({
                ...event,
                isAllDay: true,
                category: 'allday'
            }));
            hasAllDayEvents = true;
        } else if (view === 'agenda') {
            filteredEvents = allEvents;
            hasAllDayEvents = allEvents.some(event => event.isAllDay);
        } else if (view === 'week') {
            const weekStart = new Date(currentDate);
            weekStart.setDate(currentDate.getDate() - currentDate.getDay() + 1);
            const weekEnd = new Date(weekStart);
            weekEnd.setDate(weekStart.getDate() + 6);
            filteredEvents = allEvents.filter(event =>
                (event.start >= weekStart && event.start <= weekEnd) ||
                (event.end >= weekStart && event.end <= weekEnd) ||
                (event.start < weekStart && event.end > weekEnd)
            );
            hasAllDayEvents = filteredEvents.some(event => event.isAllDay);
        } else if (view === 'day') {
            const dayStart = new Date(currentDate);
            dayStart.setHours(0, 0, 0, 0);
            const dayEnd = new Date(currentDate);
            dayEnd.setHours(23, 59, 59, 999);
            filteredEvents = allEvents.filter(event =>
                (event.start >= dayStart && event.start <= dayEnd) ||
                (event.end >= dayStart && event.end <= dayEnd) ||
                (event.start < dayStart && event.end > dayEnd)
            ).map(event => {
                if (event.isAllDay) {
                    const eventStart = new Date(event.start);
                    const eventEnd = new Date(event.end);
                    const truncatedDay = this.getTotalDate(currentDate);
                    const startOfDay = new Date(truncatedDay);
                    startOfDay.setHours(8, 0, 0, 0);
                    const endOfDay = new Date(truncatedDay);
                    endOfDay.setHours(20, 0, 0, 0);
                    if (startOfDay.getTime() <= eventStart.getTime()) {
                        return { ...event, isAllDay: false, category: 'time', start: event.start, end: new Date(eventStart.setHours(20, 0, 0, 0)) };
                    } else if (endOfDay.getTime() >= eventEnd.getTime()) {
                        return { ...event, isAllDay: false, category: 'time', start: new Date(eventEnd.setHours(8, 0, 0, 0)), end: event.end };
                    } else {
                        return { ...event, isAllDay: true, category: 'allday' };
                    }
                }
                return event;
            });
            hasAllDayEvents = filteredEvents.some(event => event.isAllDay);
        }

        this.setState({ filteredEvents, hasAllDayEvents });
    }

    getTotalDate = (date) => {
        return new Date(date.getFullYear(), date.getMonth(), date.getDate());
    }

    handlePrevClick = () => {
        this.props.onPrev(this.props.view);
    }

    handleNextClick = () => {
        this.props.onNext(this.props.view);
    }

    handleViewChange = (view) => {
        if (this.props.onViewChange) {
            this.props.onViewChange(view);
        }
    }

    formatDatePopup = (date) => {
        const pad = (n) => n.toString().padStart(2, '0');

        const day = pad(date.getDate());
        const month = pad(date.getMonth() + 1);
        const year = date.getFullYear();

        const hours = pad(date.getHours());
        const minutes = pad(date.getMinutes());

        return `${day}/${month}/${year} ${hours}:${minutes}`;
    }

    formatDateSelector = () => {
        const { view, currentDate, language, t } = this.props;
        const pad = (n) => n.toString().padStart(2, '0');

        if (view === 'day') {
            const day = pad(currentDate.getDate());
            const month = currentDate.toLocaleDateString(language, { month: 'long' });
            return `${day} ${t("MONTHS.of")} ${month.charAt(0).toUpperCase() + month.slice(1)}`;
        } else if (view === 'week') {
            const weekStart = new Date(currentDate);
            weekStart.setDate(currentDate.getDate() - currentDate.getDay() + 1);
            const weekEnd = new Date(weekStart);
            weekEnd.setDate(weekStart.getDate() + 6);
            const startDay = pad(weekStart.getDate());
            const endDay = pad(weekEnd.getDate());
            const month = currentDate.toLocaleDateString(language, { month: 'long' });
            return `${startDay}-${endDay} ${t("MONTHS.of")} ${month.charAt(0).toUpperCase() + month.slice(1)}`;
        } else if (view === 'month' || view === 'agenda') {
            const month = currentDate.toLocaleDateString(language, { month: 'long' });
            const year = currentDate.getFullYear();
            return `${month.charAt(0).toUpperCase() + month.slice(1)} ${year}`;
        }
        return '';
    }

    handleEventClick = (eventInfo) => {
        const event = eventInfo.event;
        this.setState({
            selectedEvent: event,
            showModal: true
        });
    };

    handleCloseDetailModal = () => {
        this.setState({ showModal: false, selectedEvent: null });
        this.loadEventsForMonth();
    }

    hasPermission() {
        return hasAdminRole() || hasManagerRole() || hasProfesionalRole()
    }

    render() {
        const { t, currentDate, language, timeZone, view } = this.props;
        const { filteredEvents, hasAllDayEvents, showModal, selectedEvent, showEditModal } = this.state;
        const formattedDate = this.formatDateSelector();
        const repeatIcon = `
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path fill="currentColor" d="M11 5.466V4H5a4 4 0 0 0-3.584 5.777a.5.5 0 1 1-.896.446A5 5 0 0 1 5 3h6V1.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384l-2.36 1.966a.25.25 0 0 1-.41-.192m3.81.086a.5.5 0 0 1 .67.225A5 5 0 0 1 11 13H5v1.466a.25.25 0 0 1-.41.192l-2.36-1.966a.25.25 0 0 1 0-.384l2.36-1.966a.25.25 0 0 1 .41.192V12h6a4 4 0 0 0 3.585-5.777a.5.5 0 0 1 .225-.67Z"/></svg>
        `;
        const template = {
            time: function (event) {
                const isRecurrent = event.raw.isRecurrent;

                return `<div class="custom-event" style="background-color: ${event.backgroundColor};">
                    ${isRecurrent ? repeatIcon : ''}
                    <span class="event-title">${event.title}</span>
                </div>`;
            },
            allday: function (event) {
                const isRecurrent = event.raw.isRecurrent;

                return `<div class="custom-event" style="background-color: ${event.backgroundColor};">
                ${isRecurrent ? repeatIcon : ''}
                <span class="event-title">${event.title}</span>
            </div>`;
            },
            alldayTitle: function () {
                return `<span>${t("CALENDAR.LABEL.AllDay")}</span>`;
            },
            weekGridFooterExceed(hiddenEvents) {
                return `+${hiddenEvents}`;
            },
            monthGridHeaderExceed(hiddenEvents) {
                return `+${hiddenEvents}`;
            },
            timegridDisplayPrimaryTime({ time }) {
                const date = new Date(time.d.d);
                const options = { hour: '2-digit', minute: '2-digit', hour12: false, timeZone };
                return date.toLocaleTimeString(language, options);
            },
            monthMoreTitleDate(moreTitle) {
                const { date } = moreTitle;
                const calendarDate = new Date(currentDate);
                const month = calendarDate.toLocaleString('default', { month: 'long' });

                return `<span>${date} ${month}</span>`;
            },
        };

        const eventView = hasAllDayEvents ? ['time', 'allday'] : ['time'];

        const weekDays = [
            t('CALENDAR.WEEKDAYS.Sunday').toUpperCase(),
            t('CALENDAR.WEEKDAYS.Monday').toUpperCase(),
            t('CALENDAR.WEEKDAYS.Tuesday').toUpperCase(),
            t('CALENDAR.WEEKDAYS.Wednesday').toUpperCase(),
            t('CALENDAR.WEEKDAYS.Thursday').toUpperCase(),
            t('CALENDAR.WEEKDAYS.Friday').toUpperCase(),
            t('CALENDAR.WEEKDAYS.Saturday').toUpperCase()
        ];

        const theme = {
            common: {
                holiday: {
                    color: '#1B1B1B',
                    backgroundColor: '#E6F4FF66',
                },
                saturday: {
                    color: '#1B1B1B',
                    backgroundColor: '#E6F4FF66',
                },
                sunday: {
                    color: '#1B1B1B',
                    backgroundColor: '#EEEEEE',
                },
                dayGrid: {
                    color: '#1B1B1B',
                },
                today: {
                    color: 'white',
                },
            },
            week: {
                timeGridHalfHourLine: {
                    borderBottom: '1px dotted #EEE',
                },
                nowIndicatorLabel: {
                    color: '#C40024',
                },
                nowIndicatorPast: { border: '1px dashed #C40024' },
                nowIndicatorBullet: { backgroundColor: '#C40024' },
                nowIndicatorToday: { border: '1px solid #C40024' },
                nowIndicatorFuture: { border: '1px solid #C40024' },
                weekend: {
                    color: '#1B1B1B',
                    backgroundColor: '#E6F4FF66',
                },
                today: {
                    color: 'inherit',
                    backgroundColor: 'none',
                }
            },
            month: {
                weekend: {
                    color: '#1B1B1B',
                    backgroundColor: '#E6F4FF66',
                },
            },
        };

        return (
            <div className={styles.calendarView}>
                <div className={styles.calendarContainer}>
                    <div className={styles.header}>
                        <div className={styles.dateSelector}>
                            <Icon icon="mingcute:down-line" className={styles.navButtonLeft} onClick={this.handlePrevClick} />
                            <span>{formattedDate}</span>
                            <Icon icon="mingcute:down-line" className={styles.navButtonRight} onClick={this.handleNextClick} />
                        </div>
                        <div className={styles.viewButtons}>
                            <button className={view === 'day' ? styles.active : ''} onClick={() => this.handleViewChange('day')}>{t('CALENDAR.LABEL.DAY')}</button>
                            <button className={view === 'week' ? styles.active : ''} onClick={() => this.handleViewChange('week')}>{t('CALENDAR.LABEL.WEEK')}</button>
                            <button className={view === 'month' ? styles.active : ''} onClick={() => this.handleViewChange('month')}>{t('CALENDAR.LABEL.MONTH')}</button>
                            <button className={view === 'agenda' ? styles.active : ''} onClick={() => this.handleViewChange('agenda')}>{t('CALENDAR.LABEL.AGENDA')}</button>
                        </div>
                    </div>
                    {view === 'agenda' ? (
                        <AgendaView
                            events={filteredEvents}
                            currentDate={currentDate}
                        />
                    ) : (
                        <Calendar
                            ref={this.props.calendarRef}
                            className={`${styles.calendar}`}
                            height="85dvh"
                            view={view}
                            events={filteredEvents}
                            template={template}
                            isReadOnly={true}
                            week={{
                                startDayOfWeek: 1,
                                hourStart: 8,
                                hourEnd: 20,
                                taskView: false,
                                eventView: eventView,
                                dayNames: weekDays,
                            }}
                            day={{
                                hourStart: 8,
                                hourEnd: 20,
                                taskView: false,
                                eventView: eventView,
                            }}
                            month={{
                                startDayOfWeek: 1,
                                dayNames: weekDays,
                                narrowWeekend: false,
                                isAlways6Weeks: false,
                            }}
                            theme={theme}
                            calendars={[
                                {
                                    id: '1',
                                    name: 'Contenidos',
                                    color: '#ffffff',
                                    borderColor: 'none',
                                }
                            ]}
                            onClickEvent={this.handleEventClick}
                        />
                    )}
                </div>

                {showModal && selectedEvent && (
                    <Modal
                        title={selectedEvent.title}
                        titleClassName={styles.modalTitle}
                        onClose={() => this.setState({ showModal: false, selectedEvent: null, showEditModal: false })}
                    >
                        <div>
                            <p className={styles.center}>
                                {this.formatDatePopup(new Date(selectedEvent.raw.startDate))} - {this.formatDatePopup(new Date(selectedEvent.raw.endDate))}
                            </p>
                            {selectedEvent.raw.resource && (
                                <p>
                                    <a href={selectedEvent.raw.resource} target="_blank" rel="noopener noreferrer" className={styles.link}>
                                        {selectedEvent.raw.resource.length > 50 ? `${selectedEvent.raw.resource.substring(0, 50)}...` : selectedEvent.raw.resource}
                                    </a>
                                </p>
                            )}
                            {this.hasPermission() && (
                                <div className={styles.buttonGroup}>
                                    <LiveToggleButton
                                        occurrenceId={selectedEvent.id}
                                        categoryName={selectedEvent.raw.content.category.name}
                                        groupId={selectedEvent.raw.content.groupId}
                                        isLive={selectedEvent.raw.live}
                                        onClose={this.handleCloseDetailModal}
                                        setIsLive={(newLiveStatus) => {
                                            this.setState(prevState => ({
                                                selectedEvent: {
                                                    ...prevState.selectedEvent,
                                                    raw: {
                                                        ...prevState.selectedEvent.raw,
                                                        live: newLiveStatus
                                                    }
                                                }
                                            }));
                                        }}
                                    />
                                    <Tooltip title={t("CALENDAR.LABEL.EditContent")} arrow>
                                        <IconButton onClick={() => this.setState({ showEditModal: true })} style={{ color: 'black' }}>
                                            <Icon icon="akar-icons:edit" className={styles.iconButton} width="20"
                                                height="20" />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            )}
                        </div>

                    </Modal>
                )}

                {showEditModal &&
                    <EditContentModal
                        onClose={(updatedContent) => {
                            if (updatedContent) {
                                this.setState(prevState => ({
                                    selectedEvent: {
                                        ...prevState.selectedEvent,
                                        start: new Date(updatedContent.startDate),
                                        end: new Date(updatedContent.endDate),
                                        raw: {
                                            ...prevState.selectedEvent.raw,
                                            startDate: updatedContent.startDate,
                                            endDate: updatedContent.endDate,
                                        }
                                    },
                                    showEditModal: false,
                                    isSubmitting: false,
                                    editedStartDate: null,
                                    editedEndDate: null,
                                }), () => {
                                    this.loadEventsForMonth();
                                });
                            } else {
                                this.setState({
                                    showEditModal: false,
                                    editedStartDate: null,
                                    editedEndDate: null,
                                    error: null,
                                });
                            }
                        }}
                        event={this.state.selectedEvent}
                    />
                }

            </div>
        );
    }
}

const mapStateToProps = state => ({
    language: state.language.language,
    timeZone: state.timeZone.timeZone,
});

export default connect(mapStateToProps)(withTranslation()(CustomCalendar));
