import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
import contactImg from './img/contact.svg';
import groupImg from './img/group.svg';
import styles from './styles/empty-content.module.scss';

const views = [
    {
        key: 'contact',
        image: contactImg,
        title: 'EMPTY-COMPONENT.CONTACTS.title',
        description: 'EMPTY-COMPONENT.CONTACTS.description',
    },
    {
        key: 'groups',
        image: groupImg,
        title: 'EMPTY-COMPONENT.GROUPS.title',
        description: 'EMPTY-COMPONENT.GROUPS.description',
    }
];

class EmptyContent extends Component {
    render() {
        const { t, view } = this.props;
        const currentView = views.find(v => v.key === view);

        if (!currentView) {
            return null;
        }

        return (
            <div className={styles.container}>
                <div className={styles.img}>
                    <img src={currentView.image} alt={currentView.title} width="210px" height="144px"/>
                </div>
                <div className={styles.title}>
                    {t(currentView.title)}
                </div>
                <div className={styles.description}>
                    {t(currentView.description)}
                </div>
            </div>
        );
    }
}

export default withTranslation()(EmptyContent);
