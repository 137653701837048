import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from 'react-redux';
import Modal from "../../../modal/modal.component";
import LoadingScreen from "../../../loading-screen/loadingScreen.component";
import { Icon } from "@iconify/react";
import styles from "./styles/groupSelectUsers.module.scss";
import { getContactsByUserId } from "../../../../services/UserService";

class GroupSelectUsers extends Component {
  state = {
    allUsers: [],
    users: [],
    selectedUsers: this.props.selectedUsers || [],
    searchTerm: "",
    isLoading: true,
  };

  componentDidMount() {
    this.fetchContacts();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedUsers !== this.props.selectedUsers) {
      this.setState({ selectedUsers: this.props.selectedUsers });
    }
  }

  fetchContacts = async () => {
    const { user } = this.props;
    this.setState({ isLoading: true });
    try {
      const contacts = await getContactsByUserId(user.id);
      this.setState({ allUsers: contacts, users: contacts, isLoading: false });
    } catch (error) {
      console.error("Error fetching contacts:", error);
      this.setState({ isLoading: false });
    }
  };

  handleUserToggle = (user) => {
    this.setState((prevState) => {
      const { selectedUsers } = prevState;
      const isSelected = selectedUsers.includes(user.id);

      const newSelectedUsers = isSelected
        ? selectedUsers.filter((id) => id !== user.id)
        : [...selectedUsers, user.id];

      return { selectedUsers: newSelectedUsers };
    });
  };

  handleCheckboxChange = (user, event) => {
    event.stopPropagation();
    this.handleUserToggle(user);
  };

  handleSearchChange = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    this.setState({ searchTerm }, () => {
      this.filterUsers();
    });
  };

  filterUsers = () => {
    const { allUsers, searchTerm } = this.state;
    const filteredUsers = allUsers.filter((user) =>
      user.name.toLowerCase().includes(searchTerm) ||
      user.surname.toLowerCase().includes(searchTerm) ||
      user.email.toLowerCase().includes(searchTerm)
    );
    this.setState({ users: filteredUsers });
  };

  handleSubmit = () => {
    this.props.onSubmit(this.state.selectedUsers);
  };

  render() {
    const { t, onClose } = this.props;
    const { users, selectedUsers, isLoading, searchTerm } = this.state;

    const buttons = [
      {
        label: t("GROUPS.LABEL.ConfirmButton"),
        className: styles.confirmButton,
        onClick: this.handleSubmit,
      },
      {
        label: t("GROUPS.LABEL.CancelButton"),
        className: styles.cancelButton,
        onClick: onClose,
      },
    ];

    return (
      <>
        {isLoading && <LoadingScreen />}
        <Modal
          title={t("GROUPS.LABEL.TitleSelectUsers")}
          titleClassName={styles.modalTitle}
          buttons={buttons}
        >
          <div className={`${styles.searchContainer} ${searchTerm ? styles.active : ''}`}>
            <input
              className={styles.searchInput}
              type="text"
              value={searchTerm}
              onChange={this.handleSearchChange}
              placeholder={t('GROUPS.LABEL.SearchUsersPlaceholder')}
            />
            <Icon icon="lucide:search" width="20px" height="20px" className={styles.searchIcon} />
          </div>
          <div className={styles.userListContainer}>
            {users.map((user) => (
              <div
                key={user.id}
                className={`${styles.userItem} ${selectedUsers.includes(user.id) ? styles.selected : ""}`}
                onClick={() => this.handleUserToggle(user)}
              >
                <input
                  type="checkbox"
                  className={styles.checkbox}
                  checked={selectedUsers.includes(user.id)}
                  onChange={(event) => this.handleCheckboxChange(user, event)}
                  onClick={(event) => event.stopPropagation()}
                />
                <span className={styles.userName}>{user.name} {user.surname}</span>
              </div>
            ))}
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(withTranslation()(GroupSelectUsers));
