import {errorHandler} from '../utils/errorHandler.js';

const API_URL = process.env.REACT_APP_BACKEND_API_URL;
const token = sessionStorage.getItem('accessToken');

const TenantService = {
    getAllTenants: async () => {
        try {
            const response = await fetch(API_URL + '/api/v1/tenants', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            errorHandler(response.status);

            if (response.ok) {
                return await response.json();
            }
            return [];
        } catch (error) {
            console.error('Error fetching all tenants:', error);
            throw error;
        }
    },
    findTenants: async (names) => {
        try {
            const response = await fetch(API_URL + '/api/v1/tenants/search?' + names, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            errorHandler(response.status);

            if (response.ok) {
                return await response.json();
            }
            return [];
        } catch (error) {
            console.error('Error fetching tenants by names:', error);
            throw error;
        }
    }
}

export const { getAllTenants, findTenants } = TenantService;