import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  videocallInfo: null,
  showVideocall: false,
};

const videocallSlice = createSlice({
  name: 'videcall',
  initialState,
  reducers: {
    setVideocallInfo(state, action) {
      state.videocallInfo = action.payload;
    },
    clearVideocallInfo(state) {
      state.videocallInfo = null;
    },
    setShowVideocall(state, action) {
      state.showVideocall = action.payload;
    },
    clearShowVideocall(state) {
      state.showVideocall = false;
    }
  },
});

export const { setVideocallInfo, clearVideocallInfo, setShowVideocall, clearShowVideocall } = videocallSlice.actions;
export default videocallSlice.reducer;
