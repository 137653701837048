import React, { useState } from "react";
import { IconButton, Tooltip } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import { useDispatch } from "react-redux";
import { setShowVideocall, setVideocallInfo } from "../../../redux/slices/videocallSlice";
import { setUserStatus } from "../../../redux/slices/userSlice";
import { State } from "../../../utils/StateEnum";
import { changeUserStateToStartCall, changeUserStateToEndCall } from "../../../services/UserStateService";
import { callGroup } from "../../../services/GroupService";
import { playRingtone } from "../../../utils/videocall.util";
import { useNavigate } from 'react-router-dom';
import { patchContentOccurrence } from "../../../services/ContentService";
import { store } from "../../../redux/store";
import { withTranslation } from 'react-i18next';
import { t } from "i18next";

const LiveToggleButton = ({ isLive, setIsLive, categoryName, groupId, occurrenceId, onClose }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleCreateCall = async () => {
        if (!isLive) {
            if (categoryName === "Actividades_Grupo") {
                try {
                    const userId = store.getState().user.id;

                    callGroup(groupId)
                        .then(callResponse => {
                            console.log("[LiveToggleButton] Call initiated:", callResponse);
                            dispatch(setVideocallInfo(callResponse));
                            dispatch(setShowVideocall(true));
                            dispatch(setUserStatus({ status: State.BUSY }));
                            changeUserStateToStartCall(userId);
                            playRingtone(true);
                            onClose();
                            navigate('/videocall');
                        })
                        .catch(error => {
                            console.error('Error inicializando llamada', error);
                            dispatch(setUserStatus({ status: State.ONLINE }));
                            changeUserStateToEndCall(userId);
                        });

                } catch (error) {
                    console.error('Error al crear la videollamada o actualizar el contenido:', error);
                }
            }

            const patchData = {
                live: true
            };

            try {
                const updatedContent = await patchContentOccurrence(occurrenceId, patchData);
                console.log('Contenido actualizado exitosamente:', updatedContent);
                setIsLive(updatedContent.live);
            } catch (error) {
                console.error('Error al actualizar el contenido:', error);
            }
        } else {
            const patchData = {
                live: false
            };

            try {
                const updatedContent = await patchContentOccurrence(occurrenceId, patchData);
                console.log('Contenido pausado exitosamente:', updatedContent);
                setIsLive(updatedContent.live);
                onClose();
            } catch (error) {
                console.error('Error al pausar el contenido:', error);
            }
        }
    };

    return (
        <Tooltip title={isLive ? t("CALENDAR.LABEL.PauseLive") : t("CALENDAR.LABEL.StartLive")} arrow>
            <IconButton onClick={handleCreateCall} style={{ color: 'green' }}>
                {isLive ? <PauseIcon /> : <PlayArrowIcon />}
            </IconButton>
        </Tooltip>
    );
};

export default withTranslation()(LiveToggleButton);
