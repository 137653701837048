import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
import Modal from '../modal/modal.component';
import { Icon } from '@iconify/react';
import styles from './styles/modalEnableNotifications.module.scss';
import { requestToken } from '../../services/notification/FirebaseService';

class ModalEnableNotifications extends Component {
  state = {
    showModal: false,
    isPermissionDenied: false,
  };

  componentDidMount() {
    this.checkNotificationPermission();
  }

  checkNotificationPermission = () => {
    if (Notification.permission === 'denied') {
      this.setState({ showModal: true, isPermissionDenied: true });
    } else if (Notification.permission !== 'granted') {
      this.setState({ showModal: true, isPermissionDenied: false });
    } else {
      this.setState({ showModal: false });
      if (this.props.onClose) {
        this.props.onClose(true);
      }
    }
  };

  handleEnableNotifications = async () => {
    try {
      const permission = await Notification.requestPermission();
      if (permission === 'granted') {
        await requestToken();

        this.setState({ showModal: false });
        if (this.props.onClose) {
          this.props.onClose(true);
        }
      } else {
        this.setState({ showModal: false });
        if (this.props.onClose) {
          this.props.onClose(false);
        }
      }
    } catch (error) {
      console.error('Error enabling notifications', error);
      this.setState({ showModal: false });
      if (this.props.onClose) {
        this.props.onClose(false);
      }
    }
  };

  render() {
    const { t } = this.props;
    const { showModal, isPermissionDenied } = this.state;

    if (!showModal) {
      return null;
    }

    const buttons = isPermissionDenied
      ? [
          {
            label: t("NOTIFICATIONS.ENABLE.CloseButton"),
            className: styles.enableButton,
            onClick: () => {
              this.setState({ showModal: false });
              if (this.props.onClose) {
                this.props.onClose(false);
              }
            }
          }
        ]
      : [
          {
            label: t("NOTIFICATIONS.ENABLE.EnableButton"),
            className: styles.enableButton,
            onClick: this.handleEnableNotifications
          },
          {
            label: t("NOTIFICATIONS.ENABLE.CancelButton"),
            className: styles.cancelButton,
            onClick: () => {
              this.setState({ showModal: false });
              if (this.props.onClose) {
                this.props.onClose(false);
              }
            }
          }
        ];

    const icon = <Icon icon="mdi:bell-off-outline" className={styles.icon} />;

    return (
      <Modal
        title={t("NOTIFICATIONS.ENABLE.Title")}
        titleClassName={styles.modalTitle}
        buttons={buttons}
        image={icon}
        onClose={() => {
          this.setState({ showModal: false });
          if (this.props.onClose) {
            this.props.onClose(false);
          }
        }}
      >
        <div className={styles.messages}>
          {isPermissionDenied ? (
            <p>{t("NOTIFICATIONS.ENABLE.PermissionDeniedMessage")}</p>
          ) : (
            <>
              <p>{t("NOTIFICATIONS.ENABLE.Message1")}</p>
              <p>{t("NOTIFICATIONS.ENABLE.Message2")}</p>
            </>
          )}
        </div>
      </Modal>
    );
  }
}

export default withTranslation()(ModalEnableNotifications);
