import {store} from '../redux/store.js';
import {errorHandler} from '../utils/errorHandler.js';

const API_URL = process.env.REACT_APP_BACKEND_API_URL;
const token = sessionStorage.getItem('accessToken');

const VideoCallService = {
    makeRequest: async (endpoint, method, body = null) => {
        const tenantName = store.getState().tenant.tenant;
        try {
            const response = await fetch(`${API_URL}/api/v1/video-call/${endpoint}`, {
                method: method,
                headers: {
                    'Content-Type': 'application/json',
                    'X-Tenant-ID': `${tenantName}`,
                    'Authorization': `Bearer ${token}`
                },
                body: body ? JSON.stringify(body) : null
            });

            errorHandler(response.status);

            if (response.ok) {
                return response.json();
            }
            return [];
        } catch (error) {
            console.error(`Error in ${endpoint} request:`, error);
            throw error;
        }
    },

    getHistory: async (contactId) => {
        return await VideoCallService.makeRequest(`${contactId}/history`, 'GET');
    },
    createCall: async (contact) => {
        const request = {
            recipients: [contact]
        };
        return await VideoCallService.makeRequest('create', 'POST', request);
    },
    createCallGroup: async (users,callerEmail) => {

      const recipients = users.filter(user => user.email !== callerEmail).map(user => ({
        id: user.id,
        name: user.name,
        surname: user.surname,
        email: user.email
      }));
      console.log("recipients",recipients);
      const request = {
        recipients: recipients
      };
         return await VideoCallService.makeRequest('create', 'POST', request);
     },
    notifyVideoCallRejection: async (videoCall) => {
        return await VideoCallService.makeRequest('reject', 'POST', videoCall);
    },
    notifyVideoCallCancelation: async (videoCall) => {
        return await VideoCallService.makeRequest('cancel', 'POST', videoCall);
    },
    notifyVideoCallAcceptation: async (videoCall) => {
        return await VideoCallService.makeRequest('accept', 'POST', videoCall);
    },
    getVideoCallCredentials: async (roomId) => {
        return await VideoCallService.makeRequest(`credentials/${roomId}`, 'GET');
    }
}

export const { getHistory, createCall,createCallGroup, notifyVideoCallRejection, notifyVideoCallAcceptation, notifyVideoCallCancelation, getVideoCallCredentials } = VideoCallService;
