import React, { Component } from 'react';
import Modal from '../../modal/modal.component';
import { Icon } from '@iconify/react';
import styles from './styles/homeUserTreeModal.module.scss';
import { searchHomes, getUsersByHomes } from '../../../services/HomeService';
import { withTranslation } from 'react-i18next';

class HomeUserTreeModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: '',
      homes: [],
      expandedHomes: {},
      selectedUsers: new Set(props.initialSelectedUsers || []),
    };
  }

  componentDidMount() {
    this.handleSearch();
  }

  handleSearchTermChange = (e) => {
    this.setState({ searchTerm: e.target.value });
  };

  handleSearch = async () => {
    const { searchTerm } = this.state;

    try {
      const homes = await searchHomes(searchTerm);
      const homeIds = homes.map((home) => home.id);

      const usersByHome = await getUsersByHomes(homeIds);

      if (!Array.isArray(usersByHome)) {
        console.error('getUsersByHomes debe devolver una lista de usuarios.');
        return;
      }

      const homesWithUsers = homes.map((home) => ({
        ...home,
        users: usersByHome.filter(
          (user) => user.homeIds && user.homeIds.includes(home.id)
        ),
      }));

      this.setState({ homes: homesWithUsers }, () => {
        this.expandHomesWithSelectedUsers();
      });
    } catch (error) {
      console.error('Error al realizar la búsqueda:', error);
    }
  };

  expandHomesWithSelectedUsers = () => {
    const { homes, selectedUsers } = this.state;
    const expandedHomes = { ...this.state.expandedHomes };

    homes.forEach((home) => {
      const anyUserSelected = home.users.some(user => selectedUsers.has(user.id));
      if (anyUserSelected) {
        expandedHomes[home.id] = true;
      }
    });

    this.setState({ expandedHomes });
  };

  toggleHomeExpansion = (homeId) => {
    this.setState((prevState) => ({
      expandedHomes: {
        ...prevState.expandedHomes,
        [homeId]: !prevState.expandedHomes[homeId],
      },
    }));
  };

  handleSelectUser = (homeId, userId) => {
    this.setState((prevState) => {
      const newSelectedUsers = new Set(prevState.selectedUsers);

      if (newSelectedUsers.has(userId)) {
        newSelectedUsers.delete(userId);
      } else {
        newSelectedUsers.add(userId);
      }

      return {
        selectedUsers: newSelectedUsers,
      };
    });
  };

  handleSelectAllUsers = (e) => {
    const { homes } = this.state;
    const newSelectedUsers = new Set();

    if (e.target.checked) {
      homes.forEach((home) => {
        home.users.forEach((user) => newSelectedUsers.add(user.id));
      });
    }

    this.setState({ selectedUsers: newSelectedUsers }, () => {
      if (newSelectedUsers.size > 0) {
        const expandedHomes = {};
        homes.forEach((home) => {
          if (home.users.length > 0) {
            expandedHomes[home.id] = true;
          }
        });
        this.setState({ expandedHomes });
      }
    });
  };

  handleConfirm = () => {
    const { selectedUsers } = this.state;
    const { onSubmit } = this.props;
    onSubmit(Array.from(selectedUsers));
  };

  handleCancel = () => {
    const { onClose } = this.props;
    onClose();
  };

  render() {
    const {
      searchTerm,
      homes,
      expandedHomes,
      selectedUsers,
    } = this.state;
    const { t } = this.props;

    return (
      <Modal
        title={t('CONTENT.CREATE.SelectHomesAndUsers')}
        onClose={this.handleCancel}
        buttons={[
          {
            label: t('CONTENT.CREATE.ConfirmButton'),
            className: styles.confirmButton,
            onClick: this.handleConfirm,
          },
          {
            label: t('CONTENT.CREATE.CancelButton'),
            className: styles.cancelButton,
            onClick: this.handleCancel,
          }
        ]}
      >
        <div className={styles.homeUserTreeContainer}>
          <div className={styles.searchGroup}>
            <div
              className={`${styles.searchContainer} ${
                searchTerm ? styles.active : ''
              }`}
            >
              <input
                className={styles.searchInput}
                type="text"
                value={searchTerm}
                onChange={this.handleSearchTermChange}
                placeholder={t('CONTENT.SEARCH.SearchHomes')}
              />
              <Icon
                icon="lucide:search"
                width="20px"
                height="20px"
                className={styles.searchIcon}
              />
            </div>
            <button onClick={this.handleSearch} className={styles.searchButton}>
              {t('CONTENT.SEARCH.SearchButton')}
            </button>
          </div>

          <div className={styles.selectedUsersCount}>
            {t('CONTENT.SEARCH.SelectedUsersCount')}: {selectedUsers.size}
          </div>

          {homes.length === 0 ? (
            <div className={styles.noHomesMessage}>
              {t('CONTENT.SEARCH.NoHomesFound')}
            </div>
          ) : (
            <div className={styles.tableContainer}>
              <table className={styles.usersTable}>
                <thead>
                  <tr>
                    <th>
                      <input
                        type="checkbox"
                        onChange={this.handleSelectAllUsers}
                        checked={
                          selectedUsers.size > 0 &&
                          homes.every(home =>
                            home.users.every(user => selectedUsers.has(user.id))
                          )
                        }
                        className={styles.checkbox}
                      />
                    </th>
                    <th>{t('CONTENT.TABLE.NIU')}</th>
                    <th>{t('CONTENT.TABLE.Name')}</th>
                    <th>{t('CONTENT.TABLE.EmailAddress')}</th>
                    <th>{t('CONTENT.TABLE.UserType')}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {homes.map((home) => (
                    <React.Fragment key={home.id}>
                      <tr className={styles.homeRow}>
                        <td>
                          <input
                            type="checkbox"
                            checked={
                              home.users.length > 0 &&
                              home.users.every(user => selectedUsers.has(user.id))
                            }
                            onChange={() => {
                              // Seleccionar o deseleccionar todos los usuarios de este hogar
                              const newSelectedUsers = new Set(selectedUsers);
                              const allUsersSelected = home.users.every(user => selectedUsers.has(user.id));
                              if (allUsersSelected) {
                                home.users.forEach(user => newSelectedUsers.delete(user.id));
                              } else {
                                home.users.forEach(user => newSelectedUsers.add(user.id));
                              }
                              this.setState({ selectedUsers: newSelectedUsers });
                            }}
                            className={styles.checkbox}
                          />
                        </td>
                        <td onClick={() => this.toggleHomeExpansion(home.id)}>
                          {home.niu || 'N/A'}
                        </td>
                        <td onClick={() => this.toggleHomeExpansion(home.id)}>
                          {home.name}
                        </td>
                        <td onClick={() => this.toggleHomeExpansion(home.id)}>
                          {home.address || 'N/A'}
                        </td>
                        <td onClick={() => this.toggleHomeExpansion(home.id)}></td>
                        <td onClick={() => this.toggleHomeExpansion(home.id)}>
                          <Icon
                            icon="mingcute:right-line"
                            className={`${styles.icon} ${
                              expandedHomes[home.id] ? styles.open : ''
                            }`}
                          />
                        </td>
                      </tr>
                      {expandedHomes[home.id] &&
                        (home.users.length > 0 ? (
                          home.users.map((user) => (
                            <tr key={user.id} className={styles.userRow}>
                              <td>
                                <input
                                  type="checkbox"
                                  checked={selectedUsers.has(user.id)}
                                  onChange={() => {
                                    this.handleSelectUser(home.id, user.id);
                                  }}
                                  className={styles.checkboxUser}
                                />
                              </td>
                              <td></td>
                              <td>{`${user.name} ${user.surname}`}</td>
                              <td>{user.email}</td>
                              <td>{user.userType ? t("USER_TYPE." + user.userType.name) : t("USERS.LABEL.Null")}</td>
                              <td></td>
                            </tr>
                          ))
                        ) : (
                          <tr className={styles.noUsersRow}>
                            <td></td>
                            <td colSpan="6" className={styles.noUsersMessage}>
                              {t('CONTENT.SEARCH.NoUsersFound')}
                            </td>
                          </tr>
                        ))}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </Modal>
    );
  }
}

export default withTranslation()(HomeUserTreeModal);
