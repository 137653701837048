import React, { Component } from "react";
import { Icon } from '@iconify/react';
import styles from './styles/notificationPopupGroup.module.scss';
import { withTranslation } from 'react-i18next';
import notificationAudio from '../../assets/audio/notificationGroup.mp3'
import ButtonJoinCallNotification from '../groups-view/button-join-call-notification/buttonJoinCallNotification.component';
class NotificationPopupGroup extends Component {
    
    playNotificationSound = () => {
        const audio = new Audio(notificationAudio);
        audio.play().catch(error => {
            console.error('Error playing sound:', error);
        });
    };

    componentDidMount() {
        this.playNotificationSound();
    }

    handleOverlayClick = (e) => {
        const { onClose } = this.props;
        if (onClose && e.target === e.currentTarget) {
            onClose();
        }
    };

    render() {
        const { title, body, notification, onClose, t } = this.props;

        return (
            <div className={styles.popupContainer}>
                <div className={styles.notificationPopup}>
                    <div className={styles.info}>
                        <Icon icon="line-md:phone-call-loop" className={styles.infoIcon} />
                        <span className={styles.infoTitle}>{t("NOTIFICATIONS.LABEL.NewNotificationGroupCall")}</span>
                    </div>
                    {onClose && (
                        <button className={styles.closeButton} onClick={onClose}>
                            <Icon icon="mdi:close" />
                        </button>
                    )}

                    <h1 className={styles.popupTitle} dangerouslySetInnerHTML={{ __html: title }}></h1>

                    <div className={styles.popupBody}>
                        {body}
                    </div>
                    <div className={styles.buttonGroup}>
                        <ButtonJoinCallNotification
                            groupId={notification.groupCallId}
                            onClose={onClose}
                        />
                        <button
                            type="button"
                            className={`${styles.cancelButton}`}
                            title={t('GROUPS.LABEL.CancelButton')}
                            onClick={onClose}
                        >
                            {t('GROUPS.LABEL.CancelButton')}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(NotificationPopupGroup);
