import React, { Component } from "react";
import CustomInput from "../../custom-input/customInput.component";
import SelectButton from "../../notification-create/select-button/selectButton.component";
import styles from "./styles/editGroup.module.scss";
import { withTranslation } from "react-i18next";
import Modal from "../../modal/modal.component";
import GroupSelectUsers from "../create-group/group-select-users/groupSelectUsers.component";
import ModalInviteGroup from "../create-group/modal-invite-group/modalInviteGroup.component";

class ModalEditGroup extends Component {
    constructor(props) {
        super(props);
        const { group } = props;
        this.state = {
            validate: false,
            errors: {},
            name: group.name || "",
            selectedUsers: group.members.map(member => member.id) || [],
            guestEmails: group.guestEmails || [],
            isSelectUsersModalOpen: false,
            isInviteModalOpen: false,
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.group.id !== this.props.group.id) {
            const { group } = this.props;
            this.setState({
                name: group.name || "",
                selectedUsers: group.members.map(member => member.id) || [],
                guestEmails: group.guestEmails || [],
            });
        }
    }

    validateFields = () => {
        const { name, selectedUsers, guestEmails } = this.state;
        const { t } = this.props;
        const errors = {};

        if (!name.trim()) errors.name = t("GROUPS.LABEL.RequiredField");
        if ((!selectedUsers || selectedUsers.length === 0) && (!guestEmails || guestEmails.length === 0)) {
            errors.selectedUsers = t("GROUPS.LABEL.SelectAtLeastOneUserOrEmail");
        }

        this.setState({ errors, validate: true });

        return Object.keys(errors).length === 0;
    };

    handleSubmit = () => {
        if (this.validateFields()) {
            const { name, selectedUsers, guestEmails } = this.state;
            this.props.onSubmit({ name: name.trim(), selectedUsers, guestEmails });
        }
    };

    handleInputChange = (name, value) => {
        this.setState({ [name]: value });
    };

    openSelectUsersModal = () => {
        this.setState({ isSelectUsersModalOpen: true });
    };

    closeSelectUsersModal = () => {
        this.setState({ isSelectUsersModalOpen: false });
    };

    handleUsersSubmit = (selectedUsers) => {
        this.setState({ selectedUsers, isSelectUsersModalOpen: false });
    };

    openInviteModal = () => {
        this.setState({ isInviteModalOpen: true });
    };

    closeInviteModal = () => {
        this.setState({ isInviteModalOpen: false });
    };

    handleInviteSubmit = (guestEmails) => {
        this.setState({ guestEmails, isInviteModalOpen: false });
    };

    render() {
        const { t, onClose } = this.props;
        const { name, selectedUsers, errors, validate, isSelectUsersModalOpen, guestEmails, isInviteModalOpen } = this.state;

        const buttons = [
            {
                label: t("GROUPS.LABEL.SaveButton"),
                className: styles.confirmButton,
                onClick: this.handleSubmit,
            },
            {
                label: t("GROUPS.LABEL.CancelButton"),
                className: styles.cancelButton,
                onClick: onClose,
            },
        ];

        return (
            <>
                <Modal
                    title={t("GROUPS.LABEL.EditGroupTitle")}
                    titleClassName={styles.modalTitle}
                    buttons={buttons}
                    onClose={onClose}
                >
                    <div className={styles.groupEditModal}>
                        <div className={styles.inputGroup}>
                            <label htmlFor="name">
                                {t("GROUPS.LABEL.GroupNameQuestion")}
                                <span className={styles.requiredMark}> *</span>
                            </label>
                            <CustomInput
                                type="text"
                                name="name"
                                value={name}
                                onChange={(value) => this.handleInputChange("name", value)}
                                placeholder={t("GROUPS.LABEL.GroupNamePlaceholder")}
                                customClass={styles.inputField}
                                validators={[{ regex: /.+/, message: t("GROUPS.LABEL.RequiredField") }]}
                                validate={validate}
                                errorMessage={errors.name}
                            />

                            <label htmlFor="users">
                                {t("GROUPS.LABEL.SelectUsers")}
                                <span className={styles.requiredMark}> *</span>
                            </label>
                            <div className={styles.buttonGroup}>
                                <SelectButton
                                    icon="ph:user-thin"
                                    label={t("GROUPS.LABEL.SelectUsersButton")}
                                    selectedCount={selectedUsers.length}
                                    onClick={this.openSelectUsersModal}
                                    validate={validate}
                                    error={errors.selectedUsers}
                                />
                            </div>

                            <label htmlFor="guestEmails">
                                {t("GROUPS.LABEL.AddGuestEmails")}
                            </label>
                            <div className={styles.buttonGroup}>
                                <SelectButton
                                    icon="ph:envelope-thin"
                                    label={t("GROUPS.LABEL.AddGuestEmailsButton")}
                                    selectedCount={guestEmails.length}
                                    onClick={this.openInviteModal}
                                />
                            </div>
                        </div>
                    </div>
                </Modal>

                {isSelectUsersModalOpen && (
                    <GroupSelectUsers
                        selectedUsers={selectedUsers}
                        onSubmit={this.handleUsersSubmit}
                        onClose={this.closeSelectUsersModal}
                    />
                )}

                {isInviteModalOpen && (
                    <ModalInviteGroup
                        guestEmails={guestEmails}
                        onSubmit={this.handleInviteSubmit}
                        onClose={this.closeInviteModal}
                    />
                )}
            </>
        );
    }
}

export default withTranslation()(ModalEditGroup);
