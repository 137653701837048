import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Modal from "../../../modal/modal.component";
import CustomInput from "../../../custom-input/customInput.component";
import styles from "./styles/modalInviteGroup.module.scss";

class ModalInviteGroup extends Component {
  state = {
    guestEmails: this.props.guestEmails || [],
    selectedEmails: this.props.guestEmails || [],
    emailInput: '',
    errors: {},
  };

  handleEmailInputChange = (value) => {
    this.setState({ emailInput: value });
  };

  handleAddEmail = () => {
    const { emailInput, guestEmails, selectedEmails } = this.state;
    const { t } = this.props;

    if (emailInput.trim() === '') return;

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(emailInput.trim())) {
      this.setState({ errors: { ...this.state.errors, emailInput: t("GROUPS.LABEL.InvalidEmail") } });
      return;
    }

    if (guestEmails.includes(emailInput.trim())) {
      this.setState({ errors: { ...this.state.errors, emailInput: t("GROUPS.LABEL.EmailAlreadyAdded") } });
      return;
    }

    this.setState({
      guestEmails: [...guestEmails, emailInput.trim()],
      selectedEmails: [...selectedEmails, emailInput.trim()],
      emailInput: '',
      errors: { ...this.state.errors, emailInput: '' },
    });
  };

  handleEmailToggle = (email) => {
    this.setState((prevState) => {
      const { selectedEmails } = prevState;
      const isSelected = selectedEmails.includes(email);

      const newSelectedEmails = isSelected
        ? selectedEmails.filter((e) => e !== email)
        : [...selectedEmails, email];

      return { selectedEmails: newSelectedEmails };
    });
  };

  handleCheckboxChange = (email, event) => {
    event.stopPropagation();
    this.handleEmailToggle(email);
  };

  handleSubmit = () => {
    const { selectedEmails } = this.state;
    this.props.onSubmit(selectedEmails);
  };

  render() {
    const { t, onClose } = this.props;
    const { guestEmails, selectedEmails, emailInput, errors } = this.state;

    const buttons = [
      {
        label: t("GROUPS.LABEL.ConfirmButton"),
        className: styles.confirmButton,
        onClick: this.handleSubmit,
      },
      {
        label: t("GROUPS.LABEL.CancelButton"),
        className: styles.cancelButton,
        onClick: onClose,
      },
    ];

    return (
      <Modal
        title={t("GROUPS.LABEL.AddGuestEmails")}
        titleClassName={styles.modalTitle}
        buttons={buttons}
        onClose={onClose}
      >
        <div className={styles.emailInputContainer}>
          <CustomInput
            type="text"
            name="emailInput"
            value={emailInput}
            onChange={this.handleEmailInputChange}
            placeholder={t("GROUPS.LABEL.EmailPlaceholder")}
            customClass={styles.inputField}
            errorMessage={errors.emailInput}
          />
          <button className={styles.addButton} onClick={this.handleAddEmail}>
            {t("GROUPS.LABEL.AddEmailButton")}
          </button>
        </div>

        <div className={styles.userListContainer}>
          {guestEmails.length === 0 ? (
            <div className={styles.noUsers}>{t("GROUPS.LABEL.NoGuestEmails")}</div>
          ) : (guestEmails.map((email) => (
            <div
              key={email}
              className={`${styles.userItem} ${selectedEmails.includes(email) ? styles.selected : ""}`}
              onClick={() => this.handleEmailToggle(email)}
            >
              <input
                type="checkbox"
                className={styles.checkbox}
                checked={selectedEmails.includes(email)}
                onChange={(event) => this.handleCheckboxChange(email, event)}
                onClick={(event) => event.stopPropagation()}
              />
              <span className={styles.userText}>{email}</span>
            </div>
          ))
          )}
        </div>
      </Modal>
    );
  }
}

export default withTranslation()(ModalInviteGroup);
