import React, { Component } from "react";
import CustomInput from "../../../custom-input/customInput.component";
import SelectButton from "../../../notification-create/select-button/selectButton.component";
import styles from "./styles/modalCreateGroup.module.scss";
import { withTranslation } from "react-i18next";
import Modal from "../../../modal/modal.component";
import ModalInviteGroup from "../modal-invite-group/modalInviteGroup.component";

class ModalCreateGroup extends Component {
    state = {
        validate: false,
        errors: {},
        isInviteModalOpen: false,
    };

    validateFields = () => {
        const { name, selectedUsers, guestEmails } = this.props;
        const errors = {};

        if (!name) errors.name = this.props.t("GROUPS.LABEL.RequiredField");
        if ((!selectedUsers || selectedUsers.length === 0) && (!guestEmails || guestEmails.length === 0)) {
            errors.selectedUsers = this.props.t("GROUPS.LABEL.SelectAtLeastOneUserOrEmail");
        }

        this.setState({ errors, validate: true });

        return Object.keys(errors).length === 0;
    };

    handleSubmit = () => {
        if (this.validateFields()) {
            this.props.onSubmit();
        }
    };

    openInviteModal = () => {
        this.setState({ isInviteModalOpen: true });
    };

    closeInviteModal = () => {
        this.setState({ isInviteModalOpen: false });
    };

    handleInviteSubmit = (guestEmails) => {
        this.props.onInputChange("guestEmails", guestEmails);
        this.setState({ isInviteModalOpen: false });
    };

    render() {
        const {
            name,
            t,
            onClose,
            onOpenSelectUsers,
            selectedUsers,
            onInputChange,
            guestEmails,
        } = this.props;
        const { errors, validate, isInviteModalOpen } = this.state;

        const buttons = [
            {
                label: t("GROUPS.LABEL.CreateButton"),
                className: styles.confirmButton,
                onClick: this.handleSubmit,
            },
            {
                label: t("GROUPS.LABEL.CancelButton"),
                className: styles.cancelButton,
                onClick: onClose,
            },
        ];

        return (
            <>
                <Modal
                    title={t("GROUPS.LABEL.CreateGroupTitle")}
                    titleClassName={styles.modalTitle}
                    buttons={buttons}
                    onClose={onClose}
                >
                    <div className={styles.groupCreateModal}>
                        <div className={styles.inputGroup}>
                            <label htmlFor="name">
                                {t("GROUPS.LABEL.GroupNameQuestion")}
                                <span className={styles.requiredMark}> *</span>
                            </label>
                            <CustomInput
                                type="text"
                                name="name"
                                value={name}
                                onChange={(value) => onInputChange("name", value)}
                                placeholder={t("GROUPS.LABEL.GroupNamePlaceholder")}
                                customClass={styles.inputField}
                                validators={[{ regex: /.+/, message: t("GROUPS.LABEL.RequiredField") }]}
                                validate={validate}
                                errorMessage={errors.name}
                            />

                            <label htmlFor="users">
                                {t("GROUPS.LABEL.SelectUsers")}
                                <span className={styles.requiredMark}> *</span>
                            </label>
                            <div className={styles.buttonGroup}>
                                <SelectButton
                                    icon="ph:user-thin"
                                    label={t("GROUPS.LABEL.SelectUsersButton")}
                                    selectedCount={selectedUsers.length}
                                    onClick={onOpenSelectUsers}
                                    validate={validate}
                                    error={errors.selectedUsers}
                                />
                            </div>

                            <label htmlFor="guestEmails">
                                {t("GROUPS.LABEL.AddGuestEmails")}
                            </label>
                            <div className={styles.buttonGroup}>
                                <SelectButton
                                    icon="ph:envelope-thin"
                                    label={t("GROUPS.LABEL.AddGuestEmailsButton")}
                                    selectedCount={guestEmails.length}
                                    onClick={this.openInviteModal}
                                />
                            </div>
                        </div>
                    </div>
                </Modal>

                {isInviteModalOpen && (
                    <ModalInviteGroup
                        guestEmails={guestEmails}
                        onSubmit={this.handleInviteSubmit}
                        onClose={this.closeInviteModal}
                    />
                )}
            </>
        );
    }
}

export default withTranslation()(ModalCreateGroup);
