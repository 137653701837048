import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  tenant: null,
  allowedCalls: {}
};

const tenantSlice = createSlice({
  name: 'tenant',
  initialState,
  reducers: {
    setTenant(state, action) {
      state.tenant = action.payload;
    },
    setAllowedCalls(state, action) {
      state.allowedCalls = action.payload;
    },
    clearTenant(state) {
      state.tenant = null;
      state.allowedCalls = {};
    },
    getTenant(state) {
      return state.tenant;
    },
  },
});

export const { setTenant, setAllowedCalls, clearTenant, getTenant } = tenantSlice.actions;
export default tenantSlice.reducer;
