import { store } from '../redux/store.js';
import { errorHandler } from '../utils/errorHandler.js';

const API_URL = process.env.REACT_APP_BACKEND_API_URL;
const token = sessionStorage.getItem('accessToken');

const GroupService = {

    createGroup: async (group) => {
        const tenantName = store.getState().tenant.tenant;
        try {
            const response = await fetch(API_URL + '/api/v1/user/groups', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Tenant-ID': `${tenantName}`,
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(group)
            });

            errorHandler(response.status);

            if (response.ok) {
                // TODO: Guardar en el Store cuando se haya creado
                return response.json();
            }
            return [];
        } catch (error) {
            console.error('Error creating group:', error);
            throw error;
        }
    },

    getGroupsByUserId: async (userId) => {
        const tenantName = store.getState().tenant.tenant;
        try {
            const response = await fetch(`${API_URL}/api/v1/user/groups/user/${userId}`, {
                method: 'GET',
                headers: {
                    'X-Tenant-ID': `${tenantName}`,
                    'Authorization': `Bearer ${token}`
                }
            });

            errorHandler(response.status);

            if (response.ok) {
                return response.json();
            }
            return [];
        } catch (error) {
            console.error('Error fetching groups by user ID:', error);
            throw error;
        }
    },

    getAllGroups: async () => {
        const tenantName = store.getState().tenant.tenant;
        try {
            const response = await fetch(`${API_URL}/api/v1/user/groups`, {
                method: 'GET',
                headers: {
                    'X-Tenant-ID': `${tenantName}`,
                    'Authorization': `Bearer ${token}`
                }
            });

            errorHandler(response.status);

            if (response.ok) {
                return response.json();
            }
            return [];
        } catch (error) {
            console.error('Error fetching all groups:', error);
            throw error;
        }
    },

    getGroupById: async (groupId) => {
        const tenantName = store.getState().tenant.tenant;
        try {
            const response = await fetch(`${API_URL}/api/v1/user/groups/${groupId}`, {
                method: 'GET',
                headers: {
                    'X-Tenant-ID': `${tenantName}`,
                    'Authorization': `Bearer ${token}`
                }
            });

            errorHandler(response.status);

            if (response.ok) {
                return response.json();
            }
            return null;
        } catch (error) {
            console.error('Error fetching group by ID:', error);
            throw error;
        }
    },

    updateGroup: async (groupId, group) => {
        const tenantName = store.getState().tenant.tenant;
        try {
            const response = await fetch(`${API_URL}/api/v1/user/groups/${groupId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Tenant-ID': `${tenantName}`,
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(group)
            });

            errorHandler(response.status);

            if (response.ok) {
                return response.json();
            }
            return null;
        } catch (error) {
            console.error('Error updating group:', error);
            throw error;
        }
    },

    deleteGroup: async (groupId) => {
        const tenantName = store.getState().tenant.tenant;
        try {
            const response = await fetch(`${API_URL}/api/v1/user/groups/${groupId}`, {
                method: 'DELETE',
                headers: {
                    'X-Tenant-ID': `${tenantName}`,
                    'Authorization': `Bearer ${token}`
                }
            });

            errorHandler(response.status);

            if (response.ok) {
                return true;
            }
            return false;
        } catch (error) {
            console.error('Error deleting group:', error);
            throw error;
        }
    },

    leaveGroup: async (groupId) => {
        const tenantName = store.getState().tenant.tenant;
        try {
            const response = await fetch(`${API_URL}/api/v1/user/groups/leave/${groupId}`, {
                method: 'DELETE',
                headers: {
                    'X-Tenant-ID': `${tenantName}`,
                    'Authorization': `Bearer ${token}`
                }
            });

            errorHandler(response.status);

            if (response.ok) {
                return true;
            }
            return false;
        } catch (error) {
            console.error('Error leaving group:', error);
            throw error;
        }
    },

    callGroup: async (groupId) => {
        const tenantName = store.getState().tenant.tenant;
        try {
            const response = await fetch(`${API_URL}/api/v1/user/groups/call/${groupId}`, {
                method: 'POST',
                headers: {
                    'X-Tenant-ID': `${tenantName}`,
                    'Authorization': `Bearer ${token}`
                }
            });

            errorHandler(response.status);

            if (response.ok) {
                return response.json();
            }
            return response;
        } catch (error) {
            console.error('Error calling group:', error);
            throw error;
        }
    },
    changeLiveStatus: async (groupId, live) => {
        const tenantName = store.getState().tenant.tenant;
        console.log("CHANGE LIVE STATUS", groupId, live);
        try {
            const response = await fetch(`${API_URL}/api/v1/user/groups/live/${groupId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Tenant-ID': `${tenantName}`,
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(live)
            });

            errorHandler(response.status);

            if (response.ok) {
                return response;
            }
            return response;
        } catch (error) {
            console.error('Error changing live status:', error);
            throw error;
        }
    }
}

export const { createGroup, getGroupsByUserId, getAllGroups, getGroupById, updateGroup, deleteGroup, leaveGroup, callGroup, changeLiveStatus } = GroupService;