import React, { Component } from "react";
import UsersTable from "./users-table/usersTable.component";
import { withTranslation } from 'react-i18next';
import CreateUsers from "./users-create/createUsers.component";
import UpdateUsers from "./users-update/updateUsers.component";
import DeleteUsers from "./users-delete/deleteUsers.component";
import ModalConfirmation from '../modal-confirmation/modalConfirmation.component';
import styles from "./styles/usersView.module.scss";
import { Icon } from '@iconify/react';
import { searchUsers, getUsersById, changeStatus, changeAdmin } from '../../services/UserService';
import { connect } from 'react-redux';
import { hasAdminRole } from '../../utils/credentials.util';
import ChangeStatusUser from "./change-status-user/changeStatusUser.component";
import ChangeAdministratorUser from "./change-administrator-user/changeAdministratorUser.component";
import HomesModal from './homes-modal/homesModal.component';

const mapStateToProps = (state) => ({
    userRoles: state.user.userRoles || []
});

class UsersView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchQuery: "",
            usersData: [],
            loading: false,
            showCreateUsers: false,
            showChangeStatus: false,
            showChangeAdministrator: false,
            showUpdateUsers: false,
            showDeleteUsers: false,
            showConfirmationModal: false,
            toChangeStatus: null,
            usersToUpdate: null,
            userToDelete: null,
            scrollToTop: false,
            selectedSingle: [],
            selectedMultiple: [],
            showHomesModal: false,
            selectedHomes: [],
        };
    }

    handleSearchChange = (event) => {
        this.setState({ searchQuery: event.target.value }, this.fetchData);
    };

    handleStatusChange = async ({ id, newStatus, value }) => {
        try {
            if (value === "status") {
                await changeStatus(id);
                this.setState(prevState => ({
                    usersData: prevState.usersData.map(users =>
                        users.id === id ? { ...users, active: newStatus } : users
                    )
                }));
            } else {
                const updatedUser = await changeAdmin(id);
                this.setState(prevState => ({
                    usersData: prevState.usersData.map(users =>
                        users.id === id ? updatedUser : users
                    )
                }));
            }
        } catch (error) {
            console.error('Error changing status:', error);
        }
    };

    fetchData = async () => {
        this.setState({ loading: true, error: null });
        const { searchQuery } = this.state;

        try {
            const data = await searchUsers(searchQuery);
            this.setState({ usersData: data, loading: false });
        } catch (error) {
            this.setState({ loading: false });
        }
    };

    componentDidMount() {
        this.fetchData();
    }

    handleShowCreateUsers = () => {
        this.setState({ showCreateUsers: true });
    };

    handleCloseCreateUsers = async (toFetch) => {
        if (toFetch) {
            this.setState({ searchQuery: '' });
            await this.fetchData();
            this.setState({ showConfirmationModal: true });
        }
        this.setState({ showCreateUsers: false });
    };

    handleCloseConfirmationModal = () => {
        this.setState({ showConfirmationModal: false });
    };

    handleShowChangeStatus = (id, newStatus, value) => {
        if (value === "status") {
            this.setState({ toChangeStatus: { id, newStatus, value }, showChangeStatus: true });
        } else {
            this.setState({ toChangeStatus: { id, newStatus, value }, showChangeAdministrator: true });
        }
    };

    handleCloseChangeStatus = async (toFetch) => {
        this.setState({ showChangeStatus: false, toChangeStatus: null });
        this.setState({ showChangeAdministrator: false, toChangeStatus: null });
        if (toFetch) {
            await this.fetchData();
        }
    };

    handleShowUpdateUsers = async (users) => {
        try {
            const usersToUpdate = await getUsersById(users.id);
            this.setState({ usersToUpdate, showUpdateUsers: true });
        } catch (error) {
            console.error('Error fetching users for update:', error);
        }
    };

    handleCloseUpdateUsers = async (toFetch) => {
        if (toFetch) {
            this.setState({ searchQuery: '' });
            await this.fetchData();
        }
        this.setState({ showUpdateUsers: false, usersToUpdate: null });
    };

    handleShowDeleteUsers = (id) => {
        this.setState({ userToDelete: id, showDeleteUsers: true });
    };

    handleCloseDeleteUsers = async (toFetch) => {
        if (toFetch) {
            this.setState({ searchQuery: '' });
            await this.fetchData();
        }
        this.setState({ showDeleteUsers: false, userToDelete: null });
    };

    makeScroll = () => {
        this.setState({ scrollToTop: true });
    };

    resetScroll = () => {
        this.setState({ scrollToTop: false });
    };

    setSelectedSingle = (selectedOptions) => {
        this.setState({ selectedSingle: Array.isArray(selectedOptions) ? selectedOptions : [] });
    };

    setSelectedMultiple = (selectedOptions) => {
        this.setState({ selectedMultiple: Array.isArray(selectedOptions) ? selectedOptions : [] });
    };

    handleViewAllHomes = (homes) => {
        this.setState({ selectedHomes: homes, showHomesModal: true });
    };

    handleCloseHomesModal = () => {
        this.setState({ showHomesModal: false, selectedHomes: [] });
    };

    hasManagementPermissions() {
        return hasAdminRole()
    }

    render() {
        const { t } = this.props;
        const { showHomesModal, selectedHomes, searchQuery, usersData, loading, showCreateUsers, showUpdateUsers, showChangeStatus, showChangeAdministrator, toChangeStatus, usersToUpdate, showDeleteUsers, userToDelete, scrollToTop, showConfirmationModal } = this.state;

        return (
            <div className={styles.container}>
                <h1>{t('USERS.LABEL.Title')}</h1>
                <div className={styles.searchAndButton}>
                    <div className={styles.searchContainer}>
                        <Icon icon="lucide:search" width="18px" height="18px" className={styles.searchIcon} />
                        <input
                            type="text"
                            placeholder={t('USERS.LABEL.SearchBar')}
                            value={searchQuery}
                            onChange={this.handleSearchChange}
                            className={styles.searchBar}
                        />
                    </div>
                    {this.hasManagementPermissions() && (
                        <button className={styles.addUsersButton} onClick={this.handleShowCreateUsers}>
                            {t('USERS.LABEL.AddUsers')}
                        </button>
                    )}
                </div>
                {!loading && (
                    <UsersTable
                        usersData={usersData}
                        onStatusChange={this.handleShowChangeStatus}
                        onEdit={(users) => this.handleShowUpdateUsers(users)}
                        onDelete={(id) => this.handleShowDeleteUsers(id)}
                        onScrollToTop={this.resetScroll}
                        scrollToTop={scrollToTop}
                        onViewAll={this.handleViewAllHomes}
                    />
                )}
                {showCreateUsers && <CreateUsers onClose={this.handleCloseCreateUsers} />}
                {showChangeStatus && toChangeStatus && (
                    <ChangeStatusUser
                        onStatusChange={this.handleStatusChange}
                        onClose={this.handleCloseChangeStatus}
                        id={toChangeStatus.id}
                        newStatus={toChangeStatus.newStatus}
                        value={toChangeStatus.value}
                        activate={toChangeStatus.newStatus}
                    />
                )}
                {showChangeAdministrator && toChangeStatus && (
                    <ChangeAdministratorUser
                        onStatusChange={this.handleStatusChange}
                        onClose={this.handleCloseChangeStatus}
                        id={toChangeStatus.id}
                        newStatus={toChangeStatus.newStatus}
                        value={toChangeStatus.value}
                        activate={toChangeStatus.newStatus}
                    />
                )}
                {showUpdateUsers && <UpdateUsers user={usersToUpdate} onClose={(toFetch) => this.handleCloseUpdateUsers(toFetch)} />}
                {showDeleteUsers && <DeleteUsers userId={userToDelete} onClose={this.handleCloseDeleteUsers} />}
                {showHomesModal && (
                    <HomesModal
                        homes={selectedHomes}
                        onClose={this.handleCloseHomesModal}
                    />
                )}
                {showConfirmationModal && (
                    <ModalConfirmation
                        title={t("USERS.CREATE.SuccessTitle")}
                        customText={t("USERS.CREATE.SuccessMessage")}
                        icon="fa-solid:user-plus"
                        onClose={this.handleCloseConfirmationModal}
                    />
                )}
                <div className={styles.scrollToTopButton} onClick={this.makeScroll}>
                    <Icon icon="icon-park-outline:up" width="30px" height="30px" />
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps)(withTranslation()(UsersView));
